var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import WebNoticeComponent from './WebNoticeComponent';
/**
 * This component returns a JSX element with content pulled from local storage for HTML content blocks, images, json, web notices, and style blocks. It returns plain text for any other type.
 *
 * To return an image element, the data path should end in `image`. To return an HTML block, the data path should end in `contentHTML`. To return a style block, the data path should end in `cssBlock`. To return a json block, the data path shold end in `jsonBlock`. To return a web notice, the data path should end in `webNotices`. Any other paths will result in the raw string data being returned.
 */
export var CMSContent = function (_a) {
    var _b, _c;
    var _d = _a.localStorageObject, localStorageObject = _d === void 0 ? '' : _d, _e = _a.contentPath, contentPath = _e === void 0 ? '' : _e, cmsSourceFirebase = _a.cmsSourceFirebase, _f = _a.className, className = _f === void 0 ? '' : _f, _g = _a.noticePlacement, noticePlacement = _g === void 0 ? '' : _g, _h = _a.delayToShow, delayToShow = _h === void 0 ? 0 : _h, _j = _a.children, children = _j === void 0 ? [] : _j;
    var _k = useState(null), contentBody = _k[0], setContentBody = _k[1];
    var resolveDataNode = function (dataToRetrieve, dataObject) {
        return dataToRetrieve.split('.').reduce(function (obj, objKey) {
            return obj === null || obj === void 0 ? void 0 : obj[objKey];
        }, dataObject);
    };
    var getContent = function (localStorageObject) { return __awaiter(void 0, void 0, void 0, function () {
        var db, resultObj, contentObject, contentNode;
        return __generator(this, function (_a) {
            db = window.localStorage;
            resultObj = db.getItem(localStorageObject);
            if (resultObj !== null) {
                contentObject = JSON.parse(resultObj);
                contentNode = resolveDataNode(contentPath, contentObject);
                setContentBody(contentNode);
            }
            return [2 /*return*/];
        });
    }); };
    useEffect(function () {
        if ((cmsSourceFirebase === null || cmsSourceFirebase === void 0 ? void 0 : cmsSourceFirebase.ready) === true) {
            void getContent(localStorageObject);
        }
    }, [localStorageObject, cmsSourceFirebase === null || cmsSourceFirebase === void 0 ? void 0 : cmsSourceFirebase.ready]);
    if (contentPath.endsWith('webNotices')) {
        var webNoticesContentBody = contentBody;
        return (_jsx(WebNoticeComponent, __assign({ noticeData: webNoticesContentBody, noticePlacement: noticePlacement, className: className, delayToShow: delayToShow }, { children: children })));
    }
    else if (contentPath.endsWith('contentHTML')) {
        if (contentBody === null || contentBody === undefined)
            return null;
        return (_jsx("div", { "data-testid": 'content-html', id: "cms-".concat(localStorageObject, "-").concat(contentPath.replace(/\./g, '-')), dangerouslySetInnerHTML: { __html: String(contentBody) }, className: className }));
    }
    else if (contentPath.endsWith('cssBlock')) {
        return _jsx("style", { "data-testid": 'css-block', dangerouslySetInnerHTML: { __html: String(contentBody) } });
    }
    else if (contentPath.endsWith('jsonBlock')) {
        // TODO: Remove this condition as should be handled with the `getCMSObject` since react components should not return objects
        return (_b = JSON.parse(contentBody === null ? '{}' : String(contentBody))) !== null && _b !== void 0 ? _b : {};
    }
    else if (contentPath.endsWith('image')) {
        var contentBodyObject = contentBody;
        if ((contentBodyObject === null || contentBodyObject === void 0 ? void 0 : contentBodyObject.webLinkUrl) !== undefined) {
            return (_jsx("a", __assign({ href: contentBodyObject.webLinkUrl, rel: 'noopener noreferrer', target: (_c = contentBodyObject.webLinkTarget) !== null && _c !== void 0 ? _c : '_self' }, { children: _jsx("img", { src: contentBodyObject === null || contentBodyObject === void 0 ? void 0 : contentBodyObject.imageSrc, alt: contentBodyObject === null || contentBodyObject === void 0 ? void 0 : contentBodyObject.imageAlt, className: className }) })));
        }
        return _jsx("img", { "data-testid": 'cms-content-image', src: contentBodyObject === null || contentBodyObject === void 0 ? void 0 : contentBodyObject.imageSrc, alt: contentBodyObject === null || contentBodyObject === void 0 ? void 0 : contentBodyObject.imageAlt, className: className });
    }
    else {
        return contentBody !== null && contentBody !== void 0 ? contentBody : '';
    }
};
export default CMSContent;
