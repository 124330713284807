var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import TeleScript from '../TeleScript/TeleScript';
var FeedbackSuccess = function (_a) {
    var success = _a.success, telescript = _a.telescript;
    if (success) {
        return (_jsx("div", __assign({ className: 'alert alert-success text-center', role: 'alert' }, { children: _jsx(TeleScript, __assign({ line: telescript === null || telescript === void 0 ? void 0 : telescript.successMessage }, { children: "Thanks! We've got the message." })) })));
    }
    return null;
};
export default FeedbackSuccess;
