var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { BoxedContent, DisplayContent, TableDesktop, TableMobile, TeleScript, formatDateTBD } from '../../../index';
var RecentDrawings = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    var winner = _a.winner, actions = _a.actions, _m = _a.telescript, telescript = _m === void 0 ? {} : _m, WinnerLink = _a.WinnerLink, entryType = _a.entryType, _o = _a.createDetailsLink, createDetailsLink = _o === void 0 ? function (sweepsId) { return "/second-chance-promotion?id=".concat(sweepsId); } : _o, _p = _a.options, options = _p === void 0 ? {
        variant: 'theme-primary',
        promotionNameKey: 'sweepstake',
        hasCollapser: true,
        hash: 'recent-drawings',
        pageSize: 10,
        linkOlderDrawings: undefined,
        isVisible: false,
    } : _p;
    var _q = useState(false), pageLoaded = _q[0], setPageLoaded = _q[1];
    var _r = useState(1), page = _r[0], setPage = _r[1];
    var _s = useState((_b = options === null || options === void 0 ? void 0 : options.pageSize) !== null && _b !== void 0 ? _b : 10), pageSize = _s[0], setPageSize = _s[1];
    var hash = (_c = options === null || options === void 0 ? void 0 : options.hash) !== null && _c !== void 0 ? _c : 'drawing-entries';
    var promotionNameKey = (_d = options === null || options === void 0 ? void 0 : options.promotionNameKey) !== null && _d !== void 0 ? _d : 'sweepstake';
    var expiredDrawing = entryType ? (_e = winner === null || winner === void 0 ? void 0 : winner.expiredDrawingsByEntryType) === null || _e === void 0 ? void 0 : _e[entryType] : winner === null || winner === void 0 ? void 0 : winner.expiredDrawings;
    var _t = expiredDrawing !== null && expiredDrawing !== void 0 ? expiredDrawing : { drawings: [], total: 0 }, drawings = _t.drawings, total = _t.total;
    useEffect(function () {
        function fetchData() {
            var _a, _b;
            return __awaiter(this, void 0, void 0, function () {
                var action;
                return __generator(this, function (_c) {
                    switch (_c.label) {
                        case 0:
                            if (!!pageLoaded) return [3 /*break*/, 2];
                            action = entryType ? 'getExpiredDrawingsByEntryType' : 'getExpiredDrawings';
                            return [4 /*yield*/, ((_b = (_a = actions.winnerActions) === null || _a === void 0 ? void 0 : _a[action]) === null || _b === void 0 ? void 0 : _b.call(_a, __assign({ page: page, pagesize: pageSize }, (entryType ? { entry_type: entryType } : {}))))];
                        case 1:
                            _c.sent();
                            setPageLoaded(true);
                            _c.label = 2;
                        case 2: return [2 /*return*/];
                    }
                });
            });
        }
        fetchData();
        function resetData() {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, actions.winnerActions.reset()];
                        case 1:
                            _a.sent();
                            setPage(1);
                            setPageLoaded(false);
                            setPageSize(pageSize);
                            return [2 /*return*/];
                    }
                });
            });
        }
        return function () {
            resetData();
        };
    }, []);
    var loadMoreData = function () { return __awaiter(void 0, void 0, void 0, function () {
        var action;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    action = entryType ? 'getExpiredDrawingsByEntryType' : 'getExpiredDrawings';
                    return [4 /*yield*/, ((_b = (_a = actions.winnerActions) === null || _a === void 0 ? void 0 : _a[action]) === null || _b === void 0 ? void 0 : _b.call(_a, __assign({ page: page + 1, pagesize: pageSize }, (entryType ? { entry_type: entryType } : {}))))];
                case 1:
                    _c.sent();
                    setPage(page + 1);
                    return [2 /*return*/];
            }
        });
    }); };
    // if there are more drawings, show the link for more
    var loadMoreLink = function () {
        return (drawings === null || drawings === void 0 ? void 0 : drawings.length) > 0 && (drawings === null || drawings === void 0 ? void 0 : drawings.length) < total ? (_jsx("div", __assign({ className: 'text-center my-4' }, { children: _jsx(Button, __assign({ variant: 'outline-secondary', className: 'btn theme-btn theme-outline', onClick: function () { return loadMoreData(); } }, { children: _jsx(TeleScript, __assign({ line: telescript === null || telescript === void 0 ? void 0 : telescript.viewMoreButton }, { children: "View More" })) })) }))) : null;
    };
    var entriesTable = function (data) {
        var _a, _b, _c, _d;
        var drawings = data !== null && data !== void 0 ? data : [];
        var displayHeaders = {
            promotion: (_a = telescript === null || telescript === void 0 ? void 0 : telescript.tablePromotion) !== null && _a !== void 0 ? _a : 'Promotion',
            entryPeriod: (_b = telescript === null || telescript === void 0 ? void 0 : telescript.tableEntryPeriod) !== null && _b !== void 0 ? _b : 'Entry Period',
            drawingDate: (_c = telescript === null || telescript === void 0 ? void 0 : telescript.tableDrawingDate) !== null && _c !== void 0 ? _c : 'Drawing Date',
        };
        var displayRows = __spreadArray([], drawings, true).map(function (drawing) { return (__assign(__assign({}, drawing), { fields: {
                promotion: _jsx("a", __assign({ href: createDetailsLink(drawing === null || drawing === void 0 ? void 0 : drawing.sweepstake_id) }, { children: drawing[promotionNameKey] })),
                entryPeriod: (_jsxs(_Fragment, { children: [formatDateTBD === null || formatDateTBD === void 0 ? void 0 : formatDateTBD.dateTimeWithDayOfTheWeek(drawing === null || drawing === void 0 ? void 0 : drawing.quantum_start), " - ", formatDateTBD === null || formatDateTBD === void 0 ? void 0 : formatDateTBD.dateTimeWithDayOfTheWeek(drawing === null || drawing === void 0 ? void 0 : drawing.quantum_end)] })),
                drawingDate: (_jsxs(_Fragment, { children: [_jsx("div", { children: formatDateTBD === null || formatDateTBD === void 0 ? void 0 : formatDateTBD.monthDayYear(drawing === null || drawing === void 0 ? void 0 : drawing.draw_date) }), (drawing === null || drawing === void 0 ? void 0 : drawing.certified) && (_jsx("div", { children: _jsx(WinnerLink, { selected: drawing }) }))] })),
            } })); });
        var displayOptions = {
            tableId: 'recent-drawings-table',
            isLoaded: pageLoaded,
            messageNoRows: (_d = telescript === null || telescript === void 0 ? void 0 : telescript.noDrawings) !== null && _d !== void 0 ? _d : 'No drawings',
        };
        return (_jsxs(_Fragment, { children: [_jsx(TableDesktop, { headers: displayHeaders, rows: displayRows, options: displayOptions }), _jsx(TableMobile, { headers: displayHeaders, rows: displayRows, options: displayOptions })] }));
    };
    return (_jsx(BoxedContent, __assign({ note: 'Recent Drawings', hash: hash, variant: (_f = options === null || options === void 0 ? void 0 : options.variant) !== null && _f !== void 0 ? _f : 'theme-primary', isActive: true, isVisible: (_g = options === null || options === void 0 ? void 0 : options.isVisible) !== null && _g !== void 0 ? _g : drawings === null || drawings === void 0 ? void 0 : drawings.length, title: (_h = telescript === null || telescript === void 0 ? void 0 : telescript.title) !== null && _h !== void 0 ? _h : 'Recent Drawings', hasCollapser: (_j = options === null || options === void 0 ? void 0 : options.hasCollapser) !== null && _j !== void 0 ? _j : true }, { children: _jsx("div", __assign({ className: 'row' }, { children: _jsxs("div", __assign({ className: 'col' }, { children: [entriesTable(drawings), loadMoreLink(), _jsx(DisplayContent, __assign({ isVisible: options === null || options === void 0 ? void 0 : options.linkOlderDrawings }, { children: _jsx("div", __assign({ className: 'text-center pfd-more-link' }, { children: _jsx("a", __assign({ href: (_k = options === null || options === void 0 ? void 0 : options.linkOlderDrawings) !== null && _k !== void 0 ? _k : '/', target: (_l = options === null || options === void 0 ? void 0 : options.linkOlderDrawingsTarget) !== null && _l !== void 0 ? _l : '_blank' }, { children: _jsx(TeleScript, __assign({ line: telescript === null || telescript === void 0 ? void 0 : telescript.seeOlderDrawing }, { children: "See Older Points For Drawings Entries" })) })) })) }))] })) })) })));
};
export default RecentDrawings;
