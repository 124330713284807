var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import TableDesktop from '../TableDesktop/TableDesktop';
import TableMobile from '../TableMobile/TableMobile';
import './pfd-style.scss';
var WinnersTable = function (_a) {
    var _b, _c, _d, _e, _f;
    var winners = _a.winners, telescript = _a.telescript, hideWinnerNameColumn = _a.hideWinnerNameColumn;
    var winnersDisplayOptions = {
        tableId: 'myDrawingEntriesAndWinnersWinnersTable',
        isLoaded: true,
        messageNoRows: (_b = telescript === null || telescript === void 0 ? void 0 : telescript.noDrawings) !== null && _b !== void 0 ? _b : 'No winners',
    };
    if (hideWinnerNameColumn) {
        var cityStateDisplayHeaders = {
            city: (_c = telescript === null || telescript === void 0 ? void 0 : telescript.tableCity) !== null && _c !== void 0 ? _c : 'City',
            state: (_d = telescript === null || telescript === void 0 ? void 0 : telescript.tableState) !== null && _d !== void 0 ? _d : 'State',
        };
        var cityStateDisplayRows = (winners === null || winners === void 0 ? void 0 : winners.map(function (winner) { return (__assign(__assign({}, winner), { fields: {
                city: winner === null || winner === void 0 ? void 0 : winner.city,
                state: winner === null || winner === void 0 ? void 0 : winner.state,
            } })); })) || [];
        return (_jsx("div", __assign({ className: 'd-flex justify-content-center mt-3' }, { children: _jsxs("div", __assign({ className: 'pfd-winners-table' }, { children: [_jsx(TableDesktop, { headers: cityStateDisplayHeaders, rows: cityStateDisplayRows, options: winnersDisplayOptions }), _jsx(TableMobile, { headers: cityStateDisplayHeaders, rows: cityStateDisplayRows, options: winnersDisplayOptions })] })) })));
    }
    var nameAndCityDisplayHeaders = {
        cityState: (_e = telescript === null || telescript === void 0 ? void 0 : telescript.tableCityState) !== null && _e !== void 0 ? _e : 'City, State',
        winner: (_f = telescript === null || telescript === void 0 ? void 0 : telescript.tableWinner) !== null && _f !== void 0 ? _f : 'Winner',
    };
    var nameAndCityDisplayRows = (winners === null || winners === void 0 ? void 0 : winners.map(function (winner) { return (__assign(__assign({}, winner), { fields: {
            cityState: "".concat(winner === null || winner === void 0 ? void 0 : winner.city, ", ").concat(winner === null || winner === void 0 ? void 0 : winner.state),
            winner: "".concat(winner === null || winner === void 0 ? void 0 : winner.firstname, " ").concat(winner === null || winner === void 0 ? void 0 : winner.lastname),
        } })); })) || [];
    return (_jsx("div", __assign({ className: 'd-flex justify-content-center mt-3' }, { children: _jsxs("div", __assign({ className: 'pfd-winners-table' }, { children: [_jsx(TableDesktop, { headers: nameAndCityDisplayHeaders, rows: nameAndCityDisplayRows, options: winnersDisplayOptions }), _jsx(TableMobile, { headers: nameAndCityDisplayHeaders, rows: nameAndCityDisplayRows, options: winnersDisplayOptions })] })) })));
};
export default WinnersTable;
