import React from 'react';
import { connect } from 'react-redux';
import { BoxedContent, CMSContent, UpdateIdentification, UpdateContactInformation, hasCMSContent, getCMSObject } from 'sg-ui-components';

import { mapStateToProps, mapDispatchToProps } from '../Store';

import Layout from '../Components/Layout';
import UpdateLogin from '../Components/MyProfile/UpdateLogin';
import { OptinAccordian } from '../Components/Optins/optin_form';

import UserLockout from '../utils/user_lockout';

const MyProfilePage = ({ user, loading, config, cmsSourceFirebase, actions, match }) => {
    const updateIdentificationTelescript = getCMSObject('data.components.teleScripts.updateIdentification.jsonBlock');
    const updateContactInformationTelescript = getCMSObject('data.components.teleScripts.updateContactInformation.jsonBlock');

    let genders = getCMSObject('data.sitewideSettings.genders.jsonBlock');
    genders = Array.isArray(genders) ? genders : [];

    let states = getCMSObject('data.sitewideSettings.states.jsonBlock');
    states = Array.isArray(states) ? states : [];

    return (
        <Layout {...{ user, config, cmsSourceFirebase, actions, match }}>
            <UserLockout currentSection='my-profile' disableRedirect='change-password'>
                <div className='row justify-content-center'>
                    <div className='col-12'>
                        <h1>MY PROFILE</h1>
                    </div>
                </div>
                <div className='row justify-content-center'>
                    <div className='col-12 col-lg-10'>
                        <UpdateIdentification
                            user={user}
                            loading={loading}
                            actions={actions}
                            telescript={updateIdentificationTelescript}
                            editableFields={[]}
                            genders={genders}
                            options={{
                                variant: 'theme-primary',
                                hasCollapser: true,
                                showByDefault: true,
                                hash: 'update-identification',
                            }}
                        />

                        <UpdateLogin />

                        <UpdateContactInformation
                            user={user}
                            loading={loading}
                            actions={actions}
                            telescript={updateContactInformationTelescript}
                            editableFields={['address1', 'address2', 'city', 'state', 'zip', 'phone']}
                            states={states}
                            options={{
                                variant: 'theme-primary',
                                hasCollapser: true,
                                showByDefault: false,
                                hash: 'update-contact-information',
                            }}
                        />

                        <OptinAccordian heading='My Notification Options' />

                        <BoxedContent
                            note='Self Exclusion section'
                            hash='self-exclusion'
                            isActive={false}
                            isVisible={hasCMSContent('data.legalAndPolicyItems.selfExclusion.contentHeaderText')}
                            title={
                                <CMSContent
                                    localStorageObject='webContent'
                                    contentPath='data.legalAndPolicyItems.selfExclusion.contentHeaderText'
                                    cmsSourceFirebase={cmsSourceFirebase}
                                />
                            }>
                            <CMSContent
                                localStorageObject='webContent'
                                contentPath='data.legalAndPolicyItems.selfExclusion.contentHTML'
                                cmsSourceFirebase={cmsSourceFirebase}
                            />
                        </BoxedContent>
                    </div>
                </div>
            </UserLockout>
        </Layout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(MyProfilePage);
