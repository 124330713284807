var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import alarm from './alarm.png';
import TeleScript from '../TeleScript/TeleScript';
var SweepstakeCategories = function (_a) {
    var _b;
    var _c = _a.category, category = _c === void 0 ? 'current' : _c, _d = _a.telescript, telescript = _d === void 0 ? {} : _d;
    // Validation: only display when the promotion is expired
    if (category !== 'expired')
        return null;
    return (_jsx("div", __assign({ className: 'sweepstake-categories-element position-absolute' }, { children: _jsxs("div", __assign({ className: 'sweepstake-categories-label' }, { children: [_jsx("img", { src: alarm, alt: (_b = telescript === null || telescript === void 0 ? void 0 : telescript.categoryExpiredIcon) !== null && _b !== void 0 ? _b : 'Alarm clock icon' }), _jsx(TeleScript, __assign({ line: telescript === null || telescript === void 0 ? void 0 : telescript.categoryExpired }, { children: "Promotion Closed" }))] })) })));
};
export default SweepstakeCategories;
