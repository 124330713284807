var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { DisplayContent, CMSContent } from '../../../index';
var TicketEntryBlurb = function (_a) {
    var user = _a.user, cmsSourceFirebase = _a.cmsSourceFirebase;
    return (_jsxs("div", __assign({ className: 'ticket-entry-blurb' }, { children: [_jsx(DisplayContent, __assign({ note: 'Ticket entry blurb text for players', isVisible: user.loggedIn }, { children: _jsx(CMSContent, { localStorageObject: 'webContent', contentPath: 'data.ticketEntry.ticketEntryIntro.contentHTML', cmsSourceFirebase: cmsSourceFirebase }) })), _jsx(DisplayContent, __assign({ note: 'Ticket entry blurb text for visitors', isVisible: !user.loggedIn }, { children: _jsx(CMSContent, { localStorageObject: 'webContent', contentPath: 'data.ticketEntry.ticketEntryIntroVisitors.contentHTML', cmsSourceFirebase: cmsSourceFirebase }) }))] })));
};
export default TicketEntryBlurb;
