import { createSlice } from '@reduxjs/toolkit';

import { userSlice } from 'sg-ui-components';
import { loadingSlice } from 'sg-ui-components';

export const defaultPrizeState = {
    lastRequested: null, // possibly use this to trigger reloading?
    requestError: false,
    secondChancePrizes: [],
    pageSize: 5,
    page: 1,
    total: 0,
    prizes: [],
};

// REDUCERS
// basic actions that affect this object only

const getSecondChancePrizesReducer = (state, action) => {
    const secondChancePrizes = Array.isArray(action.payload) ? action.payload : [];

    if (secondChancePrizes) {
        return {
            ...state,
            lastRequested: Date.now(),
            secondChancePrizes: secondChancePrizes,
        };
    }

    return state;
};

const clearPrizeReducer = () => {
    return defaultPrizeState;
};

const setRequestErrorReducer = (state) => {
    return {
        ...state,
        lastRequested: Date.now(),
        requestError: true,
    };
};

export const prizeSlice = createSlice({
    name: 'prize',
    initialState: defaultPrizeState,
    reducers: {
        getSecondChancePrizes: getSecondChancePrizesReducer,
        clearPrize: clearPrizeReducer,
        setRequestError: setRequestErrorReducer,
    },
    extraReducers: {
        [userSlice.actions.logout]: () => {
            return defaultPrizeState;
        },
    },
});

// ACTUAL ACTIONS
// a place to make the actions more complex if necessary

const getSecondChancePrizes = async (dispatch, payload, apiClient) => {
    dispatch(loadingSlice.actions.setLoading({ action: 'getSecondChancePrizes' }));
    const result = await apiClient().getPlayerWinnings(payload);
    dispatch(prizeSlice.actions.getSecondChancePrizes(result));
    dispatch(loadingSlice.actions.unsetLoading({ action: 'getSecondChancePrizes' }));
};

//actions that get exported to the views
export const prizeActions = (dispatch, apiClient) => {
    return {
        getSecondChancePrizes: (payload) => getSecondChancePrizes(dispatch, payload, apiClient),
        clearPrizes: (payload) => dispatch(prizeSlice.actions.clearPrize(payload)),
    };
};
