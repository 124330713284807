var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { get } from 'lodash';
import IfMobile from '../IfMobile/IfMobile';
import TeleScript from '../TeleScript/TeleScript';
var TableMobile = function (_a) {
    var _b, _c;
    var headers = _a.headers, rows = _a.rows, telescript = _a.telescript, _d = _a.options, options = _d === void 0 ? {
        tableId: '',
        tableClass: '',
        isLoaded: true,
        messageNoRows: '',
        messageLoading: '',
        scrollMoreMessage: false,
    } : _d;
    return (_jsx(_Fragment, { children: !rows || rows.length === 0 ? (_jsx("div", __assign({ className: 'd-lg-none text-center' }, { children: _jsx("em", { children: options.isLoaded ? options.messageNoRows : (_b = options.messageLoading) !== null && _b !== void 0 ? _b : '. . .' }) }))) : (_jsxs(_Fragment, { children: [_jsx(IfMobile, { children: _jsx(TeleScript, __assign({ line: telescript === null || telescript === void 0 ? void 0 : telescript.scrollMoreText }, { children: "Swipe left to see more information." })) }), _jsx("div", __assign({ className: 'table-responsive' }, { children: _jsx("table", __assign({ id: options.tableId, className: (_c = options.tableClass) !== null && _c !== void 0 ? _c : 'table theme-table mobile entry-table d-lg-none text-center' }, { children: _jsx("tbody", { children: Object.keys(headers).map(function (header, id) {
                                var _a;
                                return (_jsxs("tr", { children: [_jsx("td", __assign({ className: 'position-sticky' }, { children: (_a = get(headers, header)) !== null && _a !== void 0 ? _a : '' })), rows.map(function (row, rowId) {
                                            var _a;
                                            return _jsx("td", { children: (_a = get(row.fields, header)) !== null && _a !== void 0 ? _a : '-' }, rowId);
                                        })] }, id));
                            }) }) })) }))] })) }));
};
export default TableMobile;
