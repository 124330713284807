var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { autoTab } from '../../../index';
var ScratchFieldInput = React.forwardRef(function (_a, ref) {
    var field = _a.field, handleFieldChange = _a.handleFieldChange, elemRefs = _a.elemRefs;
    return (_jsxs("div", __assign({ className: 'd-inline-block ticket-number-inputs' }, { children: [_jsx("input", { type: 'text', id: field.name, "data-index": field.dataindex, ref: ref, className: 'theme-input', placeholder: field.placeholder, size: field.size, maxLength: field.size, defaultValue: field.value, onChange: function (event) { return handleFieldChange(event, field); }, onKeyUp: function (event) {
                    autoTab(event, elemRefs);
                }, required: true }), _jsx("span", __assign({ className: 'dash' }, { children: "\u2014" }))] })));
});
ScratchFieldInput.displayName = 'ScratchFieldInput';
export default ScratchFieldInput;
