var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DisplayContent, FindScratchNumbers, FindDrawNumbers } from '../../../index';
var TicketSamples = function (_a) {
    var _b, _c, _d, _e;
    var config = _a.config, cmsSourceFirebase = _a.cmsSourceFirebase;
    return (_jsx("div", __assign({ className: 'row justify-content-center' }, { children: _jsxs("div", __assign({ className: 'col-12' }, { children: [_jsx(DisplayContent, __assign({ note: 'Scratch ticket samples', isVisible: (_c = (_b = config === null || config === void 0 ? void 0 : config.web) === null || _b === void 0 ? void 0 : _b.features) === null || _c === void 0 ? void 0 : _c.scratchTickets }, { children: _jsx(FindScratchNumbers.FindScratchNumbers, { cmsSourceFirebase: cmsSourceFirebase }) })), _jsx(DisplayContent, __assign({ note: 'Draw ticket samples', isVisible: (_e = (_d = config === null || config === void 0 ? void 0 : config.web) === null || _d === void 0 ? void 0 : _d.features) === null || _e === void 0 ? void 0 : _e.drawTickets }, { children: _jsx(FindDrawNumbers.FindDrawNumbers, { cmsSourceFirebase: cmsSourceFirebase }) }))] })) })));
};
export default TicketSamples;
