var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './link-card-style.scss';
var LinkCard = function (_a) {
    var title = _a.title, onClick = _a.onClick, icon = _a.icon;
    var handleKeyDown = function (event) {
        // Check if the Enter key is pressed (key code 13)
        if (event.key === 'Enter') {
            onClick();
        }
    };
    return (_jsxs("div", __assign({ className: 'link-card-container link-typography', onClick: onClick, tabIndex: 0, onKeyDown: handleKeyDown }, { children: [_jsx(FontAwesomeIcon, { className: 'link-icon mt-1', icon: icon, size: '3x' }), title] })));
};
export default LinkCard;
