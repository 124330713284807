var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
var ConfirmationModal = function (_a) {
    var show = _a.show, setShow = _a.setShow, title = _a.title, content = _a.content, footer = _a.footer;
    var handleModalClose = function () {
        setShow(false);
    };
    return (_jsxs(Modal, __assign({ show: show, onHide: handleModalClose, backdrop: 'static', keyboard: false, dialogClassName: 'confirmation-modal', contentClassName: 'confirmation-modal', closeButton: true }, { children: [_jsxs(Modal.Header, __assign({ closeButton: true }, { children: [_jsx(FontAwesomeIcon, { className: 'mt-1', icon: faCircleCheck, style: { color: '#42BE6C' }, size: '2x' }), _jsx(Modal.Title, __assign({ className: 'ml-3 font-weight-bold' }, { children: title }))] })), _jsx(Modal.Body, __assign({ className: 'ml-3' }, { children: content })), _jsx(Modal.Footer, __assign({ className: 'flex-nowrap justify-content-center align-items-start' }, { children: footer }))] })));
};
export default ConfirmationModal;
