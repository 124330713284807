var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/**
 * Component that takes in an array of options for the user
 * to make a selection from, and based on length of array determines
 * if a checkbox, radio button, selection list or a multi-selection list
 * should be displayed.
 */
var SelectionWidget = function (_a) {
    var options = _a.options, changeCallback = _a.changeCallback, _b = _a.className, className = _b === void 0 ? 'form-control theme-input' : _b, _c = _a.defaultValue, defaultValue = _c === void 0 ? null : _c, _d = _a.multiSelect, multiSelect = _d === void 0 ? false : _d, _e = _a.readOnly, readOnly = _e === void 0 ? false : _e;
    if (!(options === null || options === void 0 ? void 0 : options.length)) {
        return _jsx("p", __assign({ className: 'error' }, { children: "Error: No options provided." }));
    }
    else if (options.length === 1) {
        return (_jsx(_Fragment, { children: options.map(function (option, index) {
                var _a, _b, _c;
                return (_jsxs("div", __assign({ className: className, "data-testid": 'user-widget-checkbox' }, { children: [_jsx("input", { className: 'form-check-input', type: 'checkbox', value: (_a = option.value.toString()) !== null && _a !== void 0 ? _a : '', id: (_b = option.value.toString()) !== null && _b !== void 0 ? _b : index, disabled: readOnly, defaultChecked: !!defaultValue, onClick: changeCallback }), _jsx("label", { className: 'form-check-label', htmlFor: (_c = option.value.toString()) !== null && _c !== void 0 ? _c : index, dangerouslySetInnerHTML: { __html: option.label } })] }), index));
            }) }));
    }
    else if (options.length <= 3) {
        return (_jsx("div", __assign({ className: className, "data-testid": 'user-widget-radio' }, { children: options.map(function (option, index) {
                var _a, _b, _c;
                return (_jsxs("div", __assign({ className: 'form-check' }, { children: [_jsx("input", { className: 'form-check-input', type: 'radio', name: (_a = option.name) !== null && _a !== void 0 ? _a : 'radio-widget', disabled: readOnly !== null && readOnly !== void 0 ? readOnly : false, id: option.label.replace(/\s/g, '') + index, value: (_b = option.value.toString()) !== null && _b !== void 0 ? _b : '', defaultChecked: (_c = (defaultValue !== null && defaultValue !== void 0 ? defaultValue : '').toString() === option.value.toString()) !== null && _c !== void 0 ? _c : false, onClick: changeCallback }), _jsx("label", { className: 'form-check-label', htmlFor: option.label.replace(/\s/g, '') + index, dangerouslySetInnerHTML: { __html: option.label } })] }), index));
            }) })));
    }
    else {
        return (_jsx("select", __assign({ className: className, multiple: multiSelect, disabled: readOnly, "data-testid": 'user-widget-select', defaultValue: defaultValue !== null && defaultValue !== void 0 ? defaultValue : '', onChange: changeCallback }, { children: options.map(function (option, index) {
                return _jsx("option", { value: option.value.toString(), dangerouslySetInnerHTML: { __html: option.label } }, index);
            }) })));
    }
};
SelectionWidget.defaultProps = {
    options: [],
    changeCallback: function () {
        return;
    },
    defaultValue: null,
    multiSelect: false,
    readOnly: false,
    className: 'form-control theme-input',
};
export default SelectionWidget;
