var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from 'react-router-dom';
import { TeleScript, BoxedContent } from '../../../index';
var HomeEnterTickets = function (_a) {
    var _b;
    var user = _a.user, _c = _a.telescript, telescript = _c === void 0 ? {} : _c, children = _a.children;
    if ((user === null || user === void 0 ? void 0 : user.loggedIn) === true) {
        return _jsx(_Fragment, { children: children });
    }
    else {
        return (_jsx("div", __assign({ id: 'enter-enter-ticket-container' }, { children: _jsx(BoxedContent, __assign({ hash: 'enter-tickets', title: (_b = telescript === null || telescript === void 0 ? void 0 : telescript.title) !== null && _b !== void 0 ? _b : 'Enter Tickets', hasCollapser: false }, { children: _jsx(TeleScript, __assign({ line: telescript === null || telescript === void 0 ? void 0 : telescript.message }, { children: _jsxs("div", __assign({ className: 'enter-logged-out text-center' }, { children: [_jsx("h3", { children: "Login or Sign-up to Enter Tickets" }), _jsx("div", __assign({ className: 'line-under' }, { children: "Enter any eligible tickets to earn entries." })), _jsxs("div", __assign({ className: 'learn-more' }, { children: [_jsx("p", __assign({ className: 'mb-1' }, { children: "Need more info?" })), _jsx(Link, __assign({ to: '/feedback', className: 'btn theme-btn theme-secondary' }, { children: "Learn More" }))] }))] })) })) })) })));
    }
};
export default HomeEnterTickets;
