import React from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../Store';
import MyPrizesSecondChance from './MyPrizesSecondChance';
import MyPrizesThirdParty from './MyPrizesThirdParty';

import { ContentBox, ContentBoxHead, ContentBoxBody, ContentCollapser, isHash, getCMSObject, TeleScript } from 'sg-ui-components';
import './my-prizes.scss';

const MyPrizes = ({ prize, thirdPartyPrizes }) => {
    const hash = 'prize-history';

    const telescript = getCMSObject('data.components.teleScripts.myPrizes.jsonBlock');
    const myPrizesSecondChanceTelescript = getCMSObject('data.components.teleScripts.myPrizesSecondChance.jsonBlock');
    const myPrizesThirdPartyTelescript = getCMSObject('data.components.teleScripts.myPrizesThirdParty.jsonBlock');

    const countThirdPartyPrizes = thirdPartyPrizes?.prizes?.length ?? 0;
    const countSecondChancePrizes = prize?.secondChancePrizes?.length ?? 0;
    const noPrizes = countThirdPartyPrizes + countSecondChancePrizes === 0;

    return (
        <ContentBox variant='theme-blue' id={hash} show={isHash(hash) ? 'true' : 'false'}>
            <ContentBoxHead>
                <TeleScript line={telescript?.title}>My Prizes</TeleScript>
                <ContentCollapser />
            </ContentBoxHead>
            <ContentBoxBody>
                <MyPrizesSecondChance telescript={myPrizesSecondChanceTelescript} />
                <MyPrizesThirdParty telescript={myPrizesThirdPartyTelescript} />
                {noPrizes ? (
                    <div>
                        <p className='text-center'>
                            <TeleScript line={telescript?.noPrizes}>You currently have no prizes.</TeleScript>
                        </p>
                    </div>
                ) : null}
            </ContentBoxBody>
        </ContentBox>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(MyPrizes);
