import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../Store';

import { TableDesktop, TableMobile, isJSON, formatDate, TeleScript } from 'sg-ui-components';
import { Overlay } from 'react-bootstrap';
import Popover from 'react-bootstrap/Popover';
import './my-prizes.scss';

/**********************************************************************
 * Component: MyPrizesThirdParty
 * Purpose:   Display's the third party (Mariz) Coupons
 *
 * Props: - thirdPartyPrizes - third Party Prize store
 *          actions       -  actions table for api calls
 *          telesctipt    - telescript object for overriding static texts with the CMS
 *
 * APIs used:  /query/thirdPartyPrizes - get the list of third party coupons
 *             /query/thirdPartyPrizes/${urlArgs.barcode} - get the Martiz redirtect info for the specific barcode
 *             Maritz Redirection Servelet -  Allows us to get the URL for the user to select their prize
 *
 */
const MyPrizesThirdParty = ({ thirdPartyPrizes, actions, telescript }) => {
    const [pageLoaded, setPageLoaded] = useState(false);
    const [error] = useState('');
    const [page, setPage] = useState(1);
    const [pagesize] = useState(5);
    const [show, setShow] = useState(false);
    const target = useRef(null);
    const maritzRef = useRef(null);

    useEffect(() => {
        async function fetchData() {
            if (!pageLoaded) {
                if (thirdPartyPrizes.prizes?.length === 0) {
                    await actions.thirdPartyPrizeActions.getThirdPartyPrizes({ page: 1, pagesize: pagesize });
                }
                setPageLoaded(true);
            }
        }

        fetchData();
    }, []);

    const showOverlay = (e) => {
        target.current = e.target;
        setShow(!show);
    };

    //***********************************************************************************
    // Load next set/page of Prize History data
    //
    const loadMoreData = async () => {
        await actions.thirdPartyPrizeActions.getThirdPartyPrizes({ page: page + 1, pagesize: pagesize });
        setPage(page + 1);
    };

    // if there are more prizes, show the link for more
    const loadMoreLink = () => {
        return thirdPartyPrizes?.total > 0 && thirdPartyPrizes?.prizes.length !== thirdPartyPrizes?.total ? (
            <div className='text-center'>
                {/* eslint-disable-next-line react/no-unknown-property */}
                <button variant='outline-secondary' className='btn theme-btn theme-outline' onClick={() => loadMoreData()}>
                    <TeleScript line={telescript?.seeMore}>See More</TeleScript>
                </button>
            </div>
        ) : null;
    };

    const prizes = thirdPartyPrizes?.prizes?.length ? thirdPartyPrizes.prizes : [];

    if (!prizes.length) {
        return null;
    }

    //***********************************************************************************
    // When the view details link is pressed, get the Redirect information from LPS for this
    // specific barcode/prize and then attempt to call the Maritz API with this redirect information
    // to get the URL for the user to select their prize.    Open this URL in a new window.
    //
    const handleViewPrize = async (e, barcode) => {
        e.preventDefault();
        await actions.thirdPartyPrizeActions.getThirdPartyPrizeBarcode({ barcode: barcode });
        maritzRef.current.submit();
    };

    // Headers: Define your headers in an object
    // Rows: Add fields based on headers and the data
    const entriesTable = (data) => {
        const entries = data ?? [];

        const displayHeaders = {
            drawing: telescript?.tableDrawing ?? 'DRAWING',
            received: telescript?.tableReceived ?? 'RECEIVED',
            expires: telescript?.tableExpires ?? 'EXPIRES',
            details: telescript?.tableDetails ?? 'DETAILS',
        };

        const showPrizeDetails = (entry) => {
            // State can be AVAILABLE, HELD, ASSIGNED, REDEEMED, EXPIRED
            // Maritz requires that a form be sent to them to properly redirect the user to the Martiz store.
            if (entry.State && entry.State.toUpperCase() === 'ASSIGNED') {
                return (
                    <>
                        <form action={thirdPartyPrizes.redirectUrl} method='post' target='_blank' name='maritzForm' ref={maritzRef}>
                            <input id='PartnerIdentifier' name='PartnerIdentifier' type='hidden' value={thirdPartyPrizes.partnerIdentifier} />
                            <input id='ClientIdentifier' name='ClientIdentifier' type='hidden' value={thirdPartyPrizes.clientIdentifier} />
                            <input id='clientProjectKey' name='clientProjectKey' type='hidden' value={thirdPartyPrizes.clientProjectKey} />
                            <input id='redirectToken' name='redirectToken' type='hidden' value={thirdPartyPrizes.redirectToken} />
                            <button className='btn btn-link' onClick={(e) => handleViewPrize(e, entry.Barcode.SerialNo)}>
                                <TeleScript line={telescript?.selectMyPrizeNow}>Select My Prize Now</TeleScript>
                            </button>
                        </form>
                    </>
                );
            } else if (entry.State && entry.State.toUpperCase() === 'REDEEMED') {
                const prizeDescription = isJSON(entry?.extended_attributes)
                    ? JSON.parse(entry?.extended_attributes)?.prize_item_description
                    : entry?.extended_attributes?.prize_item_description;

                if (prizeDescription) {
                    return (
                        <>
                            <Overlay target={target.current} show={show} placement='bottom'>
                                <Popover className='prizePopover'>
                                    <TeleScript line={telescript?.selectedPrize}>
                                        <h3>Selected Prize</h3>
                                    </TeleScript>
                                    <hr />
                                    <p dangerouslySetInnerHTML={{ __html: prizeDescription ?? null }} />
                                </Popover>
                            </Overlay>
                            <button type='button' className='btn btn-link' onClick={showOverlay}>
                                <TeleScript line={telescript?.redeemedSeeDetails}>Redeemed - See Details</TeleScript>
                            </button>
                        </>
                    );
                } else
                    return (
                        <TeleScript line={telescript?.prizeDetailsNotAvailable}>
                            <p>Prize details not available</p>
                        </TeleScript>
                    );
            } else {
                return entry.State;
            }
        };

        const displayRows = entries.map((entry) => ({
            ...entry,
            fields: {
                drawing: entry.CouponName ?? 'N/A',
                received: entry.AwardedDate ? formatDate?.dateTimeWithMeridiem(formatDate?.convertDateToUnix(entry.AwardedDate * 1000)) : 'N/A',
                expires: entry.EffectiveEndDate ? formatDate?.dateTimeWithMeridiem(formatDate?.convertDateToUnix(entry.EffectiveEndDate * 1000)) : 'N/A',
                details: entry.State ? showPrizeDetails(entry) : 'N/A',
            },
        }));

        const displayOptions = {
            tableId: 'my-third-party-prizes-table',
            isLoaded: pageLoaded,
            messageNoRows: telescript?.messageNoRows ?? 'You currently have no prizes.',
        };

        return (
            <>
                <TableDesktop headers={displayHeaders} rows={displayRows} options={displayOptions} />
                <TableMobile headers={displayHeaders} rows={displayRows} options={displayOptions} />
                {loadMoreLink()}
            </>
        );
    };

    return (
        <>
            <div className='row'>
                <div className='col'>
                    <TeleScript line={telescript?.title}>
                        <h4>Merchandise Prizes</h4>
                    </TeleScript>
                </div>
            </div>
            {error ? (
                <div className='row m-1'>
                    <div className='alert alert-danger col-12' role='alert'>
                        <div className='p-0 m-0' dangerouslySetInnerHTML={{ __html: error }}></div>
                    </div>
                </div>
            ) : null}
            <div className='row'>
                <div className='col'>{entriesTable(prizes)}</div>
            </div>
        </>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(MyPrizesThirdParty);
