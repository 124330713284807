var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState, useMemo } from 'react';
import { Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { ContentBox, ContentBoxHead, ContentBoxBody } from '../ContentBox/ContentBox';
import TeleScript from '../TeleScript/TeleScript';
import { shuffle } from '../../utils/randomize';
var groupByDrawDate = function (drawings) {
    var groupedArray = [];
    var groupedDates = [];
    drawings.forEach(function (drawing) {
        var drawDate = drawing.draw_date;
        var index = groupedDates.indexOf(drawDate);
        if (index === -1) {
            groupedDates.push(drawDate);
            groupedArray.push([drawing]);
        }
        else {
            groupedArray[index].push(drawing);
        }
    });
    return groupedArray;
};
export var randomizeSameDateDrawings = function (drawings) {
    var groupedDrawingsByDate = groupByDrawDate(drawings);
    var shuffledGroupedDrawingsByDate = groupedDrawingsByDate.map(shuffle);
    return shuffledGroupedDrawingsByDate.flat();
};
/**
 * A component mostly used on the Drawings & Winners pages.
 *
 * It shows the next N drawings with a carousel.
 *
 * It's partially similar to the NextDrawing component, but there are some major differences and I expect more differences to come in the future.
 */
var NextDrawings = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h;
    var user = _a.user, winner = _a.winner, actions = _a.actions, _j = _a.formatDate, formatDate = _j === void 0 ? function () { return ''; } : _j, _k = _a.telescript, telescript = _k === void 0 ? {} : _k, _l = _a.options, options = _l === void 0 ? {
        variant: 'theme-primary',
        limitOnePerSweepstake: true,
        maxElement: 5,
        promotionDetailsLink: '/second-chance-promotion',
    } : _l, renderCarouselItem = _a.renderCarouselItem;
    var _m = useState(false), pageLoaded = _m[0], setPageLoaded = _m[1];
    useEffect(function () {
        function fetchData() {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!!pageLoaded) return [3 /*break*/, 2];
                            return [4 /*yield*/, actions.winnerActions.getNextDrawings()];
                        case 1:
                            _a.sent();
                            setPageLoaded(true);
                            _a.label = 2;
                        case 2: return [2 /*return*/];
                    }
                });
            });
        }
        fetchData();
    }, []);
    var formatDrawings = function (drawings, telescript) {
        if (!drawings || !Array.isArray(drawings))
            return [];
        return drawings.map(function (val) {
            return __assign(__assign({}, val), { promotion: val.sweepstake, drawing_date: (_jsxs(TeleScript, __assign({ line: telescript === null || telescript === void 0 ? void 0 : telescript.nextDrawingDate, replace: { '%TEMPLATE_DATE%': formatDate(val.draw_date) } }, { children: ["The Next Drawing is on ", formatDate(val.draw_date)] }))), has_winners: val.certified });
        });
    };
    var dataFormatted = formatDrawings(winner.nextDrawings, telescript);
    var sortedDrawings = dataFormatted
        .filter(function (val) {
        return val.draw_date != null;
    })
        .sort(function (a, b) {
        return a.id - b.id;
    })
        .sort(function (a, b) {
        return a.draw_date - b.draw_date; // TODO: change to make a proper comparisson of strings
    });
    var randomizedByDrawDateDrawings = useMemo(function () { return randomizeSameDateDrawings(sortedDrawings); }, [sortedDrawings.map(function (drawing) { return drawing.id; }).join(',')]);
    var keepOnlyOnePerSweepstake = function (drawings) {
        var sweepstakes = [];
        var uniques = drawings.filter(function (drawing) {
            if (!sweepstakes.includes(drawing.sweepstake_id)) {
                sweepstakes.push(drawing.sweepstake_id);
                return true;
            }
            return false;
        });
        return uniques;
    };
    var limitOnePerSweepstake = (_b = options === null || options === void 0 ? void 0 : options.limitOnePerSweepstake) !== null && _b !== void 0 ? _b : true;
    var maxElement = (_c = options === null || options === void 0 ? void 0 : options.maxElement) !== null && _c !== void 0 ? _c : 5;
    var uniqueDrawings = limitOnePerSweepstake ? keepOnlyOnePerSweepstake(randomizedByDrawDateDrawings) : randomizedByDrawDateDrawings;
    var nextDrawings = uniqueDrawings.slice(0, maxElement);
    var promotionDetailsLink = (_d = options === null || options === void 0 ? void 0 : options.promotionDetailsLink) !== null && _d !== void 0 ? _d : '/second-chance-promotion';
    //? For debug: console.log('📃 Next Drawings:', winner.nextDrawings)
    //? For debug: console.log('🎁 Filtered and sorted:', nextDrawings);
    if (!pageLoaded) {
        return (_jsxs(ContentBox, __assign({ variant: (_e = options === null || options === void 0 ? void 0 : options.variant) !== null && _e !== void 0 ? _e : 'theme-primary' }, { children: [_jsx(ContentBoxHead, { children: _jsx(TeleScript, __assign({ line: telescript === null || telescript === void 0 ? void 0 : telescript.title }, { children: "NEXT SECOND-CHANCE DRAWING" })) }), _jsx(ContentBoxBody, { children: _jsx("div", __assign({ className: 'd-none d-lg-block text-center' }, { children: _jsx("em", { children: (_f = options === null || options === void 0 ? void 0 : options.messageLoading) !== null && _f !== void 0 ? _f : '. . .' }) })) })] })));
    }
    if (nextDrawings.length) {
        return (_jsxs(ContentBox, __assign({ variant: (_g = options === null || options === void 0 ? void 0 : options.variant) !== null && _g !== void 0 ? _g : 'theme-primary' }, { children: [_jsx(ContentBoxHead, { children: _jsx(TeleScript, __assign({ line: telescript === null || telescript === void 0 ? void 0 : telescript.title }, { children: "NEXT SECOND-CHANCE DRAWING" })) }), _jsx(ContentBoxBody, { children: _jsx(Carousel, __assign({ className: 'next-drawing-carousel overflow-hidden', indicators: nextDrawings.length > 1, controls: nextDrawings.length > 1 }, { children: nextDrawings.map(function (val, index) {
                            return (_jsx(Carousel.Item, __assign({ className: 'text-center', interval: 5000 }, { children: renderCarouselItem ? (renderCarouselItem(val)) : (_jsxs("div", __assign({ className: "next-drawing-card mt-2 ".concat(nextDrawings.length > 1 ? 'mb-5' : 'mb-0') }, { children: [_jsx("h3", { children: val.promotion }), _jsx("div", { children: val.drawing_date }), val.sweepstake_id && (_jsxs("div", { children: [_jsx("hr", { className: 'theme-hr' }), _jsxs("a", __assign({ className: 'btn theme-btn theme-secondary', href: "".concat(promotionDetailsLink, "?id=").concat(val.sweepstake_id) }, { children: [' ', "DETAILS", ' '] }))] }))] }))) }), index));
                        }) })) })] })));
    }
    else
        return (_jsxs(ContentBox, __assign({ variant: (_h = options === null || options === void 0 ? void 0 : options.variant) !== null && _h !== void 0 ? _h : 'theme-primary' }, { children: [_jsx(ContentBoxHead, { children: _jsx(TeleScript, __assign({ line: telescript === null || telescript === void 0 ? void 0 : telescript.title }, { children: "NEXT SECOND-CHANCE DRAWING" })) }), _jsx(ContentBoxBody, { children: _jsxs("div", __assign({ className: 'next-drawing-card' }, { children: [_jsxs(TeleScript, __assign({ line: telescript === null || telescript === void 0 ? void 0 : telescript.noPromotions }, { children: [_jsx("h3", { children: "Stay Tuned!" }), _jsxs("div", { children: ["More drawings coming your way.", _jsx("br", {}), "Check back often for a 2nd Chance to Win!"] })] })), _jsx("div", __assign({ className: 'more-chances' }, { children: user.loggedIn ? (_jsxs(TeleScript, __assign({ line: telescript === null || telescript === void 0 ? void 0 : telescript.moreNotification }, { children: [_jsxs("p", { children: ["Want updates on promotions and drawings?", _jsx("br", {}), "Opt-in receive 2nd Chance notification."] }), _jsx(HashLink, __assign({ className: 'btn theme-btn btn-block mx-auto', to: 'my-profile#notification' }, { children: "Notification Preferences" }))] }))) : (_jsx(TeleScript, __assign({ line: telescript === null || telescript === void 0 ? void 0 : telescript.logInToCheck }, { children: _jsx(Link, __assign({ className: 'are-you-entered', to: '/login' }, { children: _jsxs("p", { children: ["Are you entered?", _jsx("br", {}), "Login to find out. Good luck!"] }) })) }))) }))] })) })] })));
};
export default NextDrawings;
