var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { formatDate } from '../../utils/formatDates';
import { pluralize } from '../../utils/pluralize';
import convertStatusToTelescriptValue from '../../utils/convertStatusToTelescriptValue';
import TableDesktop from '../TableDesktop/TableDesktop';
import TableMobile from '../TableMobile/TableMobile';
import TeleScript from '../TeleScript/TeleScript';
import './pfd-style.scss';
var Transactions = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h;
    var transactions = _a.transactions, telescript = _a.telescript, totalNumTransactions = _a.totalNumTransactions, seeMoreButton = _a.seeMoreButton;
    var displayHeaders = {
        entryId: (_b = telescript === null || telescript === void 0 ? void 0 : telescript.tableEntryId) !== null && _b !== void 0 ? _b : 'Entry ID',
        dateTime: (_c = telescript === null || telescript === void 0 ? void 0 : telescript.tableDateTime) !== null && _c !== void 0 ? _c : 'Date/Time',
        pointsEach: (_d = telescript === null || telescript === void 0 ? void 0 : telescript.tablePointEach) !== null && _d !== void 0 ? _d : 'Pts. Each',
        entries: (_e = telescript === null || telescript === void 0 ? void 0 : telescript.tableEntries) !== null && _e !== void 0 ? _e : 'Entries',
        totalPoints: (_f = telescript === null || telescript === void 0 ? void 0 : telescript.tableTotalPoints) !== null && _f !== void 0 ? _f : 'Total Points',
        status: (_g = telescript === null || telescript === void 0 ? void 0 : telescript.tableStatus) !== null && _g !== void 0 ? _g : 'Status',
    };
    var displayOptions = {
        tableId: 'myDrawingEntriesAndWinnersTable',
        isLoaded: true,
        messageNoRows: (_h = telescript === null || telescript === void 0 ? void 0 : telescript.noDrawings) !== null && _h !== void 0 ? _h : 'No drawings',
    };
    var displayRows = (transactions === null || transactions === void 0 ? void 0 : transactions.map(function (transaction) { return (__assign(__assign({}, transaction), { fields: {
            entryId: transaction.id,
            dateTime: formatDate.monthDayYearTimeWithSlashes(transaction.created_at),
            pointsEach: Number(transaction.points) / Number(transaction.quantity),
            entries: transaction.quantity,
            totalPoints: transaction.points,
            status: (_jsx("div", __assign({ className: 'text-uppercase' }, { children: convertStatusToTelescriptValue(transaction.state, 'StatusText', telescript) }))),
        } })); })) || [];
    var totalAmount = totalNumTransactions || displayRows.length;
    return (_jsxs("div", __assign({ className: 'container' }, { children: [_jsx("div", __assign({ className: 'row w-100 d-flex justify-content-center text-center mb-2' }, { children: _jsxs(TeleScript, __assign({ line: telescript === null || telescript === void 0 ? void 0 : telescript.numEntriesDisclaimerText, replace: {
                        '%X_ENTRIES%': pluralize(totalAmount, 'entry', 'entries'),
                    } }, { children: ["You currently have ", pluralize(totalAmount, 'entry', 'entries'), " into this drawing."] })) })), _jsx(TableDesktop, { headers: displayHeaders, rows: displayRows, options: displayOptions }), _jsx(TableMobile, { headers: displayHeaders, rows: displayRows, options: displayOptions }), seeMoreButton] })));
};
export default Transactions;
