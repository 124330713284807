var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { HashLink } from 'react-router-hash-link';
import { getCMSObject, stripTags, TeleScript } from '../../../index';
export var PostClaimBox = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j;
    var imageUrl = _a.imageUrl, name = _a.name, currency = _a.currency, prize_name = _a.prize_name, intent_amount = _a.intent_amount, actual_amount = _a.actual_amount, multiplier = _a.multiplier, promotionError = _a.promotionError, isAllocatable = _a.isAllocatable;
    var postClaimMessaging = getCMSObject('data.components.teleScripts.postClaimMessaging.jsonBlock');
    if (prize_name === 'PointsForPrizes') {
        prize_name = (_b = postClaimMessaging === null || postClaimMessaging === void 0 ? void 0 : postClaimMessaging.pfpLabel) !== null && _b !== void 0 ? _b : 'Points For Prizes';
    }
    else if (prize_name === 'PointsForDrawings') {
        prize_name = (_c = postClaimMessaging === null || postClaimMessaging === void 0 ? void 0 : postClaimMessaging.pfdLabel) !== null && _c !== void 0 ? _c : 'Points For Drawings';
    }
    // clean up HTML tags from prize name and promotion error
    if (prize_name && prize_name !== '') {
        prize_name = stripTags(prize_name);
    }
    if (promotionError && promotionError !== '') {
        promotionError = stripTags(promotionError);
    }
    // Note:  Nothing details links for Experiantial Rewards.
    var viewDetailsLink = _jsx(_Fragment, {});
    if (currency === 'points') {
        viewDetailsLink = (_jsx(HashLink, __assign({ to: (_d = postClaimMessaging === null || postClaimMessaging === void 0 ? void 0 : postClaimMessaging.pointsHistoryLink) !== null && _d !== void 0 ? _d : '/my-activity#points-history' }, { children: _jsx(TeleScript, __assign({ line: postClaimMessaging === null || postClaimMessaging === void 0 ? void 0 : postClaimMessaging.pointsHistoryLinkText }, { children: "View My Points" })) })));
    }
    else if (currency === 'lotteryproducts') {
        viewDetailsLink = (_jsx(HashLink, __assign({ to: (_e = postClaimMessaging === null || postClaimMessaging === void 0 ? void 0 : postClaimMessaging.couponHistoryLink) !== null && _e !== void 0 ? _e : '/my-activity#coupons-history' }, { children: _jsx(TeleScript, __assign({ line: (_f = postClaimMessaging === null || postClaimMessaging === void 0 ? void 0 : postClaimMessaging.couponHistory) === null || _f === void 0 ? void 0 : _f.couponHistoryLinkText }, { children: "View Coupons" })) })));
    }
    else if (currency === 'storepromo') {
        viewDetailsLink = (_jsx(HashLink, __assign({ to: (_g = postClaimMessaging === null || postClaimMessaging === void 0 ? void 0 : postClaimMessaging.storePromoHistoryLink) !== null && _g !== void 0 ? _g : '/my-activity#promo-history' }, { children: _jsx(TeleScript, __assign({ line: postClaimMessaging === null || postClaimMessaging === void 0 ? void 0 : postClaimMessaging.storePromoHistoryLinkText }, { children: "View Promo Codes" })) })));
    }
    else if (currency === 'sweepstake') {
        if (isAllocatable) {
            viewDetailsLink = (_jsx(HashLink, __assign({ to: (_h = postClaimMessaging === null || postClaimMessaging === void 0 ? void 0 : postClaimMessaging.allocatableHistoryLink) !== null && _h !== void 0 ? _h : '/my-activity#allocatable-entries' }, { children: _jsx(TeleScript, __assign({ line: postClaimMessaging === null || postClaimMessaging === void 0 ? void 0 : postClaimMessaging.allocatableHistoryLinkText }, { children: "View Entries" })) })));
        }
        else {
            viewDetailsLink = (_jsx(HashLink, __assign({ to: (_j = postClaimMessaging === null || postClaimMessaging === void 0 ? void 0 : postClaimMessaging.drawingsHistoryLink) !== null && _j !== void 0 ? _j : '/my-activity#drawing-entries' }, { children: _jsx(TeleScript, __assign({ line: postClaimMessaging === null || postClaimMessaging === void 0 ? void 0 : postClaimMessaging.drawingsHistoryLinkText }, { children: "View My Drawing Entries" })) })));
        }
    }
    return (_jsx(_Fragment, { children: _jsx("div", __assign({ className: 'media my-2 text-center claim-box' }, { children: _jsx("div", __assign({ className: 'container-fluid' }, { children: _jsxs("div", __assign({ className: 'row' }, { children: [_jsx("div", __assign({ className: 'col-md-6 px-0' }, { children: imageUrl && _jsx("img", { className: 'promo-image', src: "".concat(imageUrl), alt: "".concat(name, " logo") }) })), _jsx("div", __assign({ className: 'col-md-6' }, { children: _jsxs("div", __assign({ className: 'media-body promotion-info-container' }, { children: [_jsx("h2", __assign({ className: 'promotion-name' }, { children: name })), promotionError ? _jsx("p", __assign({ className: 'mt-0 theme-text-danger' }, { children: promotionError })) : null, (multiplier !== null && multiplier !== void 0 ? multiplier : 0) > 1 ? (_jsx(_Fragment, { children: (actual_amount !== null && actual_amount !== void 0 ? actual_amount : 0) < (intent_amount !== null && intent_amount !== void 0 ? intent_amount : 0) * (multiplier !== null && multiplier !== void 0 ? multiplier : 0) ? (_jsxs("p", { children: ["Congratulations, you won ", intent_amount, " ", prize_name, " and a ", multiplier, "X multiplier for a total of", ' ', (intent_amount !== null && intent_amount !== void 0 ? intent_amount : 0) * (multiplier !== null && multiplier !== void 0 ? multiplier : 0), " ", prize_name, ". Your current points limited has reduced your amount to", ' ', actual_amount, "."] })) : (_jsxs("p", { children: ["Congratulations, you won ", intent_amount, " ", prize_name, " and a ", multiplier, "X multiplier for a total of", ' ', actual_amount, " ", prize_name, ".", ' '] })) })) : null, _jsx("p", __assign({ className: 'mt-1 reward-amount' }, { children: actual_amount })), _jsx("p", __assign({ className: 'mt-1 reward-currency' }, { children: prize_name })), _jsx("p", __assign({ className: 'mt-1 cta' }, { children: viewDetailsLink }))] })) }))] })) })) }), name) }));
};
PostClaimBox.defaultProps = {
    imageUrl: '',
    name: '',
    currency: '',
    prize_name: '',
    intent_amount: 0,
    actual_amount: 0,
    multiplier: 1,
    promotionError: '',
    isAllocatable: false,
};
export default PostClaimBox;
