var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { Alert } from 'react-bootstrap';
/**
 * Small box to show user an alert with a message.
 */
var AlertBox = function (_a) {
    var variant = _a.variant, message = _a.message, extraClassNames = _a.extraClassNames, children = _a.children;
    if (!message && !children)
        return null;
    return (_jsxs(Alert, __assign({ "data-testid": 'alert-box', variant: variant, className: " ".concat(extraClassNames) }, { children: [variant == 'danger' ? _jsx(FontAwesomeIcon, { icon: faCircleXmark }) : _jsx(FontAwesomeIcon, { icon: faCheckCircle }), message ? _jsx("div", { className: 'alert-text', dangerouslySetInnerHTML: { __html: String(message) } }) : _jsx("div", __assign({ className: 'alert-text' }, { children: children }))] })));
};
AlertBox.defaultProps = {
    variant: 'danger',
    message: null,
    extraClassNames: '',
    children: null,
};
export default AlertBox;
