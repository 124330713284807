import React from 'react';
import { connect } from 'react-redux';
import { CMSContent } from 'sg-ui-components';
import { mapStateToProps, mapDispatchToProps } from '../Store';
import Layout from '../Components/Layout';

const TermsPage = ({ user, config, cmsSourceFirebase, actions, match }) => {
    return (
        <Layout {...{ user, config, cmsSourceFirebase, actions, match }}>
            <h2 className='text-center my-4'>
                <CMSContent
                    localStorageObject='webContent'
                    contentPath='data.legalAndPolicyItems.termsOfUse.contentHeaderText'
                    cmsSourceFirebase={cmsSourceFirebase}
                />
            </h2>
            <div className='my-4'>
                <CMSContent
                    localStorageObject='webContent'
                    contentPath='data.legalAndPolicyItems.termsOfUse.contentHTML'
                    cmsSourceFirebase={cmsSourceFirebase}
                />
            </div>
        </Layout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(TermsPage);
