import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { TableDesktop, TableMobile, formatDate, TeleScript } from 'sg-ui-components';

import { mapStateToProps, mapDispatchToProps } from '../../Store';

const MyPrizesSecondChance = ({ prize, actions, telescript }) => {
    const [pageLoaded, setPageLoaded] = useState(false);

    useEffect(() => {
        async function fetchData() {
            if (!pageLoaded) {
                await actions.prizeActions.getSecondChancePrizes();
                setPageLoaded(true);
            }
        }

        fetchData();
    }, []);

    const prizes = prize?.secondChancePrizes?.length ? prize?.secondChancePrizes : [];

    if (!prizes.length) {
        return null;
    }

    // Headers: Define your headers in an object
    // Rows: Add fields based on headers and the data
    const entriesTable = (data) => {
        const entries = data ?? [];

        const displayHeaders = {
            drawing: telescript?.tableDrawing ?? 'Second Chance Drawing',
            drawingDate: telescript?.tableDrawingDate ?? 'Drawing Date',
            details: telescript?.tableDetails ?? 'DETAILS',
        };

        const displayRows = entries.map((entry) => ({
            ...entry,
            fields: {
                drawing: `${entry.sweepstake} / ${entry.drawing_group}` ?? 'N/A',
                drawingDate: formatDate?.monthDayYear(entry.draw_date) ?? 'N/A',
                details: entry.prize_name ?? 'N/A',
            },
        }));

        const displayOptions = {
            tableId: 'my-second-chance-prizes-table',
            isLoaded: pageLoaded,
            messageNoRows: telescript?.messageNoRows ?? 'You currently have no prizes.',
        };

        return (
            <>
                <TableDesktop headers={displayHeaders} rows={displayRows} options={displayOptions} />
                <TableMobile headers={displayHeaders} rows={displayRows} options={displayOptions} />
            </>
        );
    };

    return (
        <>
            <div className='row'>
                <div className='col'>
                    <TeleScript line={telescript?.title}>
                        <h4>Second Chance Prizes</h4>
                    </TeleScript>
                    {entriesTable(prizes)}
                    <TeleScript line={telescript?.disclamer}>
                        <p className='text-center'>Disclamer: If you have not been contacted for the prize, please contact the lottery directly.</p>
                    </TeleScript>
                </div>
            </div>
        </>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(MyPrizesSecondChance);
