import React, { useState } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../Store';
import * as yup from 'yup';
import { PasswordField } from '../RegisterForm/password_field';
import { ContentBox, ContentBoxHead, ContentCollapser, ContentBoxBody, isHash } from 'sg-ui-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert } from 'react-bootstrap';
import { validateNewPassword } from '../../validationRules';
import { errorMessage } from '../errorMessages';
import { PasswordStatusForm } from '../ui-components/PasswordStatusForm';
import { CheckPassword } from '../ui-components/CheckPassword';

/**********************************************************************
 * Component:  UpdateLogin
 * Purpose:    Allows users to update their email address and password
 *             for their account.   The user can update the email and
 *             password independently from one another.
 *
 * Props:      user -  user data store
 *             actions - store actions (apis
 *
 * APIs used:   userActions.updateEmail - updates player email
 *              userActions.updatePassword - updates player password
 *
 * Notes:
 */
const UpdateLogin = ({ user, actions }) => {
    const initialStepFields = {
        email: user.player.email ?? '',
        password: '',
        old_password: '',
        new_password: '',
        confirm: '',
    };

    const emailSchema = yup.object().shape({
        email: yup
            .string()
            .email('Email is invalid')
            .required('Email is required')
            .max(200, 'Email cannot exceed' + ' 200 characters.'),
        password: yup.string().required('Please enter your current password below to change your email.'),
    });

    const passwordSchema = yup.object().shape({
        old_password: yup.string().required('Please enter your current password below to change your password.'),
        new_password: validateNewPassword,

        confirm: yup
            .string()
            .required('Your new password does not match the criteria; New passwords must match.')
            .oneOf([yup.ref('new_password'), null], 'Your new password does not match the criteria; New passwords' + ' must match.'),
    });

    const [stepFields, setStepFields] = useState(initialStepFields);
    const [error, setError] = useState('');
    const [localErrors, setLocalErrors] = useState({});
    const [passwordStatus, setPasswordStatus] = useState('initial');
    const [confirmPasswordStatus, setConfirmPasswordStatus] = useState('initial');

    // if the form field changes, we will change the value in the store and
    const handleChange = async (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value.trimStart();
        const name = target.name;

        setStepFields({
            ...stepFields,
            [name]: value,
        });

        //* Password inner change
        if (name === 'new_password') {
            let passwordStatus = await CheckPassword(value);
            setPasswordStatus(passwordStatus);
        } else if (name === 'confirm') {
            let passwordStatus = await CheckPassword(value);
            if (passwordStatus === 'valid' && value === stepFields.new_password) {
                setConfirmPasswordStatus(passwordStatus);
            } else {
                setConfirmPasswordStatus('Passwords do not match.');
            }
        }
    };

    const handleUpdate = async (e, updateType) => {
        e.preventDefault();
        let valid = updateType === 'email' ? await emailSchema.isValid(stepFields) : await passwordSchema.isValid(stepFields);
        setError('');
        setLocalErrors({});
        
        if (valid) {
            setPasswordStatus('initial');
            setConfirmPasswordStatus('initial');

            await actions.userActions.updateSection({ section: 'login', status: 'initial' });

            let payloadData = {};
            if (updateType === 'email') {
                payloadData = {
                    email: stepFields.email,
                    old_password: stepFields.password,
                };
                await actions.userActions.updateEmail(payloadData);
            } else {
                payloadData = {
                    old_password: stepFields.old_password,
                    password: stepFields.new_password,
                    confirm: stepFields.confirm,
                };
                await actions.userActions.updatePassword(payloadData);
            }

            setStepFields({
                ...stepFields,
                password: '',
                old_password: '',
                new_password: '',
                confirm: '',
            });
        } else {
            if (updateType === 'email') {
                await emailSchema.validate(stepFields, { abortEarly: false }).catch(function (err) {
                    setLocalErrors(getValidationErrors(err));
                    setError(err.message);
                });
            } else {
                await passwordSchema.validate(stepFields, { abortEarly: false }).catch(function (err) {
                    setLocalErrors(getValidationErrors(err));
                    setError(err.message);
                });
            }
        }
    };

    const getValidationErrors = (err) => {
        const validationErrors = {};

        err.inner.forEach((error) => {
            if (error.path) {
                validationErrors[error.path] = error.message;
            }
        });

        setLocalErrors(validationErrors);

        return validationErrors;
    };

    const hash = 'account-information';

    return (
        <ContentBox variant='theme-blue' id={hash} show={isHash(hash) ? 'true' : 'false'}>
            <ContentBoxHead>
                Account Information
                <ContentCollapser />
            </ContentBoxHead>
            <ContentBoxBody>
                <div className='form-step'>
                    <div className='inner-step'>
                        {error ? (
                            <Alert variant='danger'>
                                <FontAwesomeIcon icon="fa-regular fa-circle-xmark" />
                                <ul className='alert-text'>
                                    {Object.values(localErrors).map((err, index) => {
                                        return <li key={index} dangerouslySetInnerHTML={{ __html: err }} />;
                                    })}
                                </ul>
                            </Alert>
                        ) : null}

                        {user.updateSection.section === 'login' && user.updateSection.status === 'success' && (
                            <Alert variant='success'>
                                <FontAwesomeIcon icon="fa-regular fa-circle-check" />
                                <span className='alert-text'>Login Information updated.</span>
                            </Alert>
                        )}

                        {user.updateSection.section === 'login' && user.updateSection.status === 'error' && (
                            <Alert variant='danger'>
                                <FontAwesomeIcon icon="fa-regular fa-circle-xmark" />
                                <span className='alert-text' dangerouslySetInnerHTML={{ __html: errorMessage(user.updateSection.code) }} />
                            </Alert>
                        )}

                        <div className='form-group'>
                            <label htmlFor='enterEmail'>Email</label>
                            <input
                                type='email'
                                className='form-control theme-input'
                                autoComplete='off'
                                id='enterEmail'
                                value={stepFields.email}
                                name='email'
                                onChange={handleChange}
                                placeholder='Enter email'
                            />
                            <small>username@domain.com</small>
                        </div>

                        <div className='form-group password-field'>
                            <label htmlFor='password'>Password</label>
                            <PasswordField name='password' placeholder='Current Password' value={stepFields.password} handleChange={handleChange} />
                            <p className='small mt-3'>
                                To update your email, enter your new email then enter and confirm your password before clicking &quot;Update Email&quot;.
                            </p>
                        </div>

                        <div className='text-center'>
                            <button type='button' className='btn theme-btn theme-secondary' onClick={(e) => handleUpdate(e, 'email')}>
                                Update Email
                            </button>
                        </div>

                        <div className='form-group password-field'>
                            <label htmlFor='old_password'>Current Password</label>
                            <PasswordField name='old_password' placeholder='Current Password' value={stepFields.old_password} handleChange={handleChange} />
                            <p className='small'>Please enter your current password before updating.</p>
                        </div>

                        <div className='form-group password-field'>
                            <label htmlFor='password'>New Password</label>
                            <p className='small'>
                                Your Password must be at least 10 characters long and include - 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special
                                character.
                            </p>
                            <PasswordField
                                id='new_password'
                                name='new_password'
                                autoComplete='off'
                                placeholder='New Password'
                                value={stepFields.new_password}
                                handleChange={handleChange}
                            />
                            {PasswordStatusForm(passwordStatus)}
                        </div>

                        <div className='form-group password-field'>
                            <label htmlFor='confirmPassword'>Confirm New Password</label>
                            <PasswordField name='confirm' placeholder='Password' value={stepFields.confirm} autoComplete='off' handleChange={handleChange} />
                            {PasswordStatusForm(confirmPasswordStatus)}
                            <p className='small'>Please retype your password.</p>
                        </div>

                        <p className='my-3 italic small'>Be sure to click the Update Information button when you are done or your changes will not be saved.</p>

                        <div className='text-center'>
                            <button type='button' className='btn theme-btn theme-secondary' onClick={(e) => handleUpdate(e, 'password')}>
                                Update Information
                            </button>
                        </div>
                    </div>
                </div>
            </ContentBoxBody>
        </ContentBox>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateLogin);
