var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Link } from 'react-router-dom';
/**
 * A trail links representing the heirarchichal structure of the users current page.
 *
 *
 *  For a promotion landing page, the following `breadcrumbData` is fine.
 * ```
 *  const breadcrumbData = [
 *      { name: 'Home', path: '/' },
 *      { name: 'Promotions', path: '/promotions' },
 *  ];
 * ```
 *
 *  For a promotion / sweeps detail page, however, we would push another object onto
 *  the array that represents the current page.
 *  `breadcrumbData.push({ name: sweepstake.name, path: '#' });`
 */
var Breadcrumbs = function (_a) {
    var data = _a.data, className = _a.className;
    if (data.length <= 1) {
        return null;
    }
    return (_jsx("nav", __assign({ "aria-label": 'breadcrumb' }, { children: _jsx("ol", __assign({ id: 'breadcrumb', className: "breadcrumb ".concat(className !== null && className !== void 0 ? className : '') }, { children: data.map(function (_a, key) {
                var name = _a.name, path = _a.path;
                return key + 1 === data.length ? (_jsx("li", __assign({ "aria-current": 'page', className: 'breadcrumb-item active' }, { children: name }), key)) : (_jsx("li", __assign({ className: 'breadcrumb-item' }, { children: _jsx(Link, __assign({ to: path }, { children: name })) }), key));
            }) })) })));
};
Breadcrumbs.defaultProps = {
    className: '',
};
export default Breadcrumbs;
