var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import states from './states.json';
/**
 * Prepopulated select List of States used in registration and on My Profile pages.
 */
var StateSelectionList = function (_a) {
    var currentVal = _a.currentVal, changeCB = _a.changeCB;
    var defaultState = states.find(function (state) { return currentVal === state.name || currentVal === state.abbr; });
    if (!defaultState) {
        defaultState = {
            name: 'Missouri',
            abbr: 'MO',
        };
    }
    return (_jsx("select", __assign({ "data-testid": 'select', value: defaultState.abbr, className: 'required form-control theme-select', id: 'state', name: 'state', onChange: changeCB }, { children: states.map(function (state) {
            return (_jsxs("option", __assign({ "data-testid": state.abbr, value: state.abbr }, { children: [state.name, " - ", state.abbr] }), state.abbr));
        }) })));
};
StateSelectionList.defaultProps = {
    currentVal: '',
    changeCB: function () {
        return;
    },
};
export default StateSelectionList;
