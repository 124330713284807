// Gets the cookie by name.
export const getCookie = (cookieName) => {
    let cookie = document.cookie
        .split('; ')
        .filter((row) => row.startsWith(cookieName))
        .map((c) => c.split('=')[1])[0];
    return cookie;
};

// This is a bit better and doesn't split on equals. This is needed fot the Auth Token that
// contains many data types. Use this method moving forward.
export const getCookieByName = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
};
