var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
var FeedbackError = function (_a) {
    var isError = _a.isError, error = _a.error;
    if (isError) {
        var errors = Object.keys(error).map(function (er) {
            return (_jsxs(_Fragment, { children: [er, ": ", error[er], _jsx("br", {})] }));
        });
        return (_jsx("div", __assign({ className: 'alert alert-danger text-center', role: 'alert' }, { children: errors.map(function (err, index) {
                return _jsx("div", { children: err }, index);
            }) })));
    }
    return null;
};
export default FeedbackError;
