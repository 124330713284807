var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { ContentBox, ContentBoxHead, ContentBoxBody, ContentCollapser, TableMobile, TableDesktop, Coupon, formatDate, TeleScript, isHash, } from '../../../index';
var MyCouponsHistory = function (_a) {
    var _b, _c, _d, _e;
    var coupon = _a.coupon, actions = _a.actions, telescript = _a.telescript, lotteryLogo = _a.lotteryLogo, barcodeHost = _a.barcodeHost, _f = _a.options, options = _f === void 0 ? {
        variant: 'theme-primary',
        hasCollapser: true,
        hash: 'coupons-history',
        pageSize: 5,
        couponClassName: 'coupon',
        couponLinkType: 'link',
    } : _f;
    var _g = useState(false), pageLoaded = _g[0], setPageLoaded = _g[1];
    var _h = useState(1), page = _h[0], setPage = _h[1];
    var _j = useState((_b = options.pageSize) !== null && _b !== void 0 ? _b : 5), pageSize = _j[0], setPageSize = _j[1];
    var hash = (_c = options.hash) !== null && _c !== void 0 ? _c : 'coupons-history';
    useEffect(function () {
        function fetchData() {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!!pageLoaded) return [3 /*break*/, 2];
                            return [4 /*yield*/, actions.couponActions.getCoupons({ page: 1 })];
                        case 1:
                            _a.sent();
                            setPageLoaded(true);
                            _a.label = 2;
                        case 2: return [2 /*return*/];
                    }
                });
            });
        }
        fetchData();
        return function () {
            var _a;
            setPage(1);
            setPageSize((_a = options === null || options === void 0 ? void 0 : options.pageSize) !== null && _a !== void 0 ? _a : 5);
            setPageLoaded(false);
            actions.couponActions.reset();
        };
    }, []);
    var loadMoreData = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, actions.couponActions.getCoupons({ page: page + 1, pagesize: pageSize })];
                case 1:
                    _a.sent();
                    setPage(page + 1);
                    return [2 /*return*/];
            }
        });
    }); };
    // if there are more items, show the link for more
    var loadMoreLink = function () {
        var _a, _b;
        return ((_a = coupon === null || coupon === void 0 ? void 0 : coupon.coupons) === null || _a === void 0 ? void 0 : _a.length) > 0 && ((_b = coupon === null || coupon === void 0 ? void 0 : coupon.coupons) === null || _b === void 0 ? void 0 : _b.length) < (coupon === null || coupon === void 0 ? void 0 : coupon.total) ? (_jsx("div", __assign({ className: 'text-center' }, { children: _jsx(Button, __assign({ variant: 'outline-secondary', className: 'btn theme-btn theme-outline', onClick: function () { return loadMoreData(); } }, { children: _jsx(TeleScript, __assign({ line: telescript.seeMoreLinkText }, { children: "See More" })) })) }))) : null;
    };
    var coupons = ((_d = coupon === null || coupon === void 0 ? void 0 : coupon.coupons) === null || _d === void 0 ? void 0 : _d.length) ? coupon.coupons : [];
    // Headers: Define your headers in an object
    // Rows: Add fields based on headers and the data
    var entriesTable = function (data) {
        var _a, _b, _c, _d, _e, _f;
        var entries = data !== null && data !== void 0 ? data : [];
        var displayHeaders = {
            couponName: (_a = telescript === null || telescript === void 0 ? void 0 : telescript.tableCouponName) !== null && _a !== void 0 ? _a : 'COUPON',
            type: (_b = telescript === null || telescript === void 0 ? void 0 : telescript.tableType) !== null && _b !== void 0 ? _b : 'TYPE',
            received: (_c = telescript === null || telescript === void 0 ? void 0 : telescript.tableReceived) !== null && _c !== void 0 ? _c : 'RECEIVED',
            expires: (_d = telescript === null || telescript === void 0 ? void 0 : telescript.tableExpires) !== null && _d !== void 0 ? _d : 'EXPIRES',
            details: (_e = telescript === null || telescript === void 0 ? void 0 : telescript.tableDetails) !== null && _e !== void 0 ? _e : 'DETAILS',
        };
        var showCouponDetails = function (entry) {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
            if (entry.State && entry.State.toUpperCase() === 'ASSIGNED') {
                return (_jsx(Coupon, { className: (_a = options === null || options === void 0 ? void 0 : options.couponClassName) !== null && _a !== void 0 ? _a : 'coupon', lottery_logo: lotteryLogo, reward_id: (_b = entry === null || entry === void 0 ? void 0 : entry.TransactionID.toString()) !== null && _b !== void 0 ? _b : (_c = entry.Barcode) === null || _c === void 0 ? void 0 : _c.SerialNo.toString(), prize_name: (_e = (_d = entry.extended_attributes) === null || _d === void 0 ? void 0 : _d.display_name) !== null && _e !== void 0 ? _e : entry.CouponName, prize_description: (_g = (_f = entry.extended_attributes) === null || _f === void 0 ? void 0 : _f.description) !== null && _g !== void 0 ? _g : '', redeem: (_j = (_h = entry.extended_attributes) === null || _h === void 0 ? void 0 : _h.redeem_instruct) !== null && _j !== void 0 ? _j : '', expiration: entry.EffectiveEndDate ? entry.EffectiveEndDate : 0, state: (_k = entry.State) !== null && _k !== void 0 ? _k : '', Url: (_l = entry.Barcode.Url) !== null && _l !== void 0 ? _l : '', barcodeHost: barcodeHost !== null && barcodeHost !== void 0 ? barcodeHost : '', linkType: (_m = options === null || options === void 0 ? void 0 : options.couponLinkType) !== null && _m !== void 0 ? _m : 'link' }));
            }
            else {
                return entry.State;
            }
        };
        var displayRows = entries.map(function (entry) {
            var _a, _b;
            return (__assign(__assign({}, entry), { fields: {
                    couponName: (_a = entry.CouponName) !== null && _a !== void 0 ? _a : 'N/A',
                    type: (_b = entry.label) !== null && _b !== void 0 ? _b : entry.CouponType,
                    received: entry.AwardedDate ? formatDate === null || formatDate === void 0 ? void 0 : formatDate.dateTimeWithMeridiem(formatDate === null || formatDate === void 0 ? void 0 : formatDate.convertDateToUnix((entry === null || entry === void 0 ? void 0 : entry.AwardedDate) * 1000)) : 'N/A',
                    expires: entry.EffectiveEndDate ? formatDate === null || formatDate === void 0 ? void 0 : formatDate.dateTimeWithMeridiem(formatDate === null || formatDate === void 0 ? void 0 : formatDate.convertDateToUnix((entry === null || entry === void 0 ? void 0 : entry.EffectiveEndDate) * 1000)) : 'N/A',
                    details: entry.State ? showCouponDetails(entry) : 'N/A',
                } }));
        });
        var displayOptions = {
            tableId: 'my-coupons-table',
            isLoaded: pageLoaded,
            messageNoRows: (_f = telescript === null || telescript === void 0 ? void 0 : telescript.messageNoRows) !== null && _f !== void 0 ? _f : 'You currently have no coupons to redeem.',
        };
        return (_jsxs(_Fragment, { children: [_jsx(TableDesktop, { headers: displayHeaders, rows: displayRows, options: displayOptions }), _jsx(TableMobile, { headers: displayHeaders, rows: displayRows, options: displayOptions })] }));
    };
    return (_jsxs(ContentBox, __assign({ variant: (_e = options === null || options === void 0 ? void 0 : options.variant) !== null && _e !== void 0 ? _e : 'theme-primary', id: hash, show: isHash(hash) ? 'true' : 'false' }, { children: [_jsxs(ContentBoxHead, { children: [_jsx(TeleScript, __assign({ line: telescript === null || telescript === void 0 ? void 0 : telescript.title }, { children: "My Coupons" })), (options === null || options === void 0 ? void 0 : options.hasCollapser) === true ? _jsx(ContentCollapser, {}) : ''] }), _jsx(ContentBoxBody, { children: _jsx("div", __assign({ className: 'row' }, { children: _jsxs("div", __assign({ className: 'col' }, { children: [entriesTable(coupons !== null && coupons !== void 0 ? coupons : []), loadMoreLink()] })) })) })] })));
};
export default MyCouponsHistory;
