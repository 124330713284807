var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ContentBox, ContentBoxHead, ContentBoxBody, TeleScript } from '../../../index';
var pluralDisplay = function (amount) { return (amount === 1 ? '' : 's'); };
var PasswordExpiring = function (_a) {
    var _b;
    var expirationDays = _a.expirationDays, updateNowLink = _a.updateNowLink, telescript = _a.telescript, remindMeLaterLink = _a.remindMeLaterLink;
    return (_jsxs("div", __assign({ className: 'login-container' }, { children: [_jsx("div", { className: 'my-5' }), _jsxs(ContentBox, __assign({ variant: 'theme-blue' }, { children: [_jsx(ContentBoxHead, { children: _jsx(TeleScript, __assign({ line: telescript === null || telescript === void 0 ? void 0 : telescript.title }, { children: "UPDATE PASSWORD" })) }), _jsxs(ContentBoxBody, { children: [_jsx("div", __assign({ className: 'text-center' }, { children: _jsx("p", { children: _jsxs(TeleScript, __assign({ line: telescript === null || telescript === void 0 ? void 0 : telescript.expiringContent, replace: {
                                            '%EXPIRATION_DAYS%': (_b = expirationDays === null || expirationDays === void 0 ? void 0 : expirationDays.toString()) !== null && _b !== void 0 ? _b : '',
                                            '%PLURAL%': pluralDisplay(expirationDays),
                                        } }, { children: ["Your password is going to expire in ", expirationDays, " day", pluralDisplay(expirationDays), ". Please update it in your profile."] })) }) })), _jsx("a", __assign({ className: 'btn theme-btn theme-secondary btn-block', href: updateNowLink }, { children: _jsx(TeleScript, __assign({ line: telescript === null || telescript === void 0 ? void 0 : telescript.updateNowLink }, { children: "Update Now" })) })), _jsx("a", __assign({ className: 'btn theme-btn theme-outline btn-block', href: remindMeLaterLink }, { children: _jsx(TeleScript, __assign({ line: telescript === null || telescript === void 0 ? void 0 : telescript.remindLaterLink }, { children: "Remind me Later" })) }))] })] }))] })));
};
export default PasswordExpiring;
