import React, { useEffect, useState, useRef } from 'react';
import * as yup from 'yup';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../Store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert } from 'react-bootstrap';
import { errorMessage } from '../errorMessages';
import { CMSContent } from 'sg-ui-components';


/**********************************************************************
 * Component:  RAFFormTemplate
 * Purpose:   Used to allow a logged in user to refer a friend and
 *            give them a promo code via email or copy/paste.
 *
 * Props: -   user  - user store
 *            actions - actions table for api calls
 *
 * APIs used:  userActions.getRafCode() - get the promo code
 */
export const RafFormTemplate = ({ user, cmsSourceFirebase, actions }) => {
    const [errors, setErrors] = useState('');
    const [rafSuccess, setRafSuccess] = useState('');
    const [myRafCode, setMyRafCode] = useState('');

    //***********************************************************************************
    // Copies the current field (ref) to the clipboard so it can be pasted elsehwere
    const copyToClipboard = (ref) => {
        setErrors('');
        setRafSuccess('');
        if (ref.current) {
            ref.current.select();
            document.execCommand('copy');
            if (ref.current.value.search('register') === -1) {
                setRafSuccess('Your Referral Code Has Been Copied');
            } else {
                setRafSuccess('Your Referral Link Has Been Copied');
            }
        }
    }; // end copyToClipboard

    //***********************************************************************************
    // Get the RAF code from the API and report errors.
    useEffect(() => {
        const getRafCode = async () => {
            await actions.userActions.getRafCode();
        };
        getRafCode().catch(setErrors(console.error));
        setMyRafCode(user.rafCode);
    }, []); // end useEffect

    //***********************************************************************************
    // Reports any errors, displays the RAF code
    useEffect(() => {
        if (user.errors && user.errors.code) setErrors(errorMessage(user.errors.code));
        setMyRafCode(user.rafCode);
    }, [user]);

    return (
        <div className='form-step'>
            <div className='inner-step'>
                <CMSContent localStorageObject='webContent' contentPath='data.referAFriendInstructions.contentHTML' cmsSourceFirebase={cmsSourceFirebase} />
                {errors ? (
                    <Alert variant='danger'>
                        <FontAwesomeIcon icon="fa-regular fa-circle-xmark" />
                        <span className='alert-text' dangerouslySetInnerHTML={{ __html: errors }} />
                    </Alert>
                ) : null}
                {rafSuccess ? (
                    <Alert variant='success'>
                        <FontAwesomeIcon icon="fa-solid fa-circle-check" />
                        <span className='alert-text'>{rafSuccess}</span>
                    </Alert>
                ) : null}
                <EmailFriendInvite actions={actions} user={user} setSuccess={setRafSuccess} setErrors={setErrors} />
                <RafLink code={myRafCode} setSuccess={setRafSuccess} setErrors={setErrors} copy={copyToClipboard} />
                <ShareRafCode code={myRafCode} setSuccess={setRafSuccess} setErrors={setErrors} copy={copyToClipboard} />
            </div>
        </div>
    );
};

const RafForm = connect(mapStateToProps, mapDispatchToProps)(RafFormTemplate);

/**********************************************************************
 * Component:  EmailFriendInvite
 * Purpose:   Used to allow a logged in user send their RAF code
 *            to an friends email address
 *
 * Props: -   actions - actions table for api calls
 *            setSuccess -  success callback - gives user indication of
 *                          successful send.
 *            setErrors -  error callback if send not successful or fails
 *                         scheme check
 *
 * APIs used: userActions.sendRafInvite - Send the RAF email out.
 */
const EmailFriendInvite = ({ actions, user, setSuccess, setErrors }) => {
    const [friendEmail, setFriendEmail] = useState('');
    const [emailSent, setEmailSent] = useState(false);

    const schema = yup.string().email('The email address is invalid').required('Email is Required');

    useEffect(() => {
        setErrors('');
        setSuccess('');
        if (user.errors) {
            setErrors(errorMessage(user.errors.code));
        } else if (emailSent) {
            setSuccess('A Referral Code has successfully been sent to ' + friendEmail);
        }
    }, [user]);

    //***********************************************************************************
    // Checks the To Field and sets the friendEmail state
    const emailChange = (event) => {
        const target = event.target;
        setFriendEmail(target.value);
    }; // end emailChange

    //***********************************************************************************
    // Checks the friend email for schema errors and if none, calls the API to
    // send the email.
    const sendFriendRequest = async () => {
        setErrors('');
        setSuccess('');
        let valid = await schema.isValid(friendEmail);
        if (valid) {
            await actions.userActions.clearErrors();
            setEmailSent(true);
            await actions.userActions.sendRafInvite({ email: friendEmail });
        } else {
            await schema.validate(friendEmail, { abortEarly: false }).catch(function (err) {
                setErrors(err.message);
            });
        }
    }; // end setFriendRequest

    return (
        <div className='form-group'>
            <label htmlFor='friendEmail' className='font-weight-bold ml-0'>
                Email Your Friend a Referral Code:
            </label>
            <div className='row'>
                <div className='col'>
                    <input
                        type='email'
                        id='friendEmail'
                        name='friendEmail'
                        placeholder='friendusername@domain.com'
                        className='raf-input'
                        onChange={emailChange}
                    />
                </div>
                <button className='raf-btn' onClick={sendFriendRequest}>
                    Send
                </button>
            </div>
        </div>
    ); // end return
}; // end EmailFriendInvite

/**********************************************************************
 * Component:  RAFLink
 * Purpose:   Packs the RAF Code in a URL that can then cut/pasted
 *            and allows the recipiant to register with that code.
 *
 * Props: -   code - the RAF promo Code
 *            copy - the copy function from the parent component
 *
 * APIs used: None
 */
const RafLink = ({ code, copy }) => {
    const [rafLink, setRafLink] = useState('Code Not Available');
    const textAreaRef = useRef(null);
    const baseURL = window.location.host;

    //***********************************************************************************
    // Construct the URL that contains the RAF code so the recipiant can register with it?Its
    useEffect(() => {
        if (code) {
            setRafLink(baseURL + '/register?rafcode=' + code);
        }
    }, [code]);

    return (
        <div className='form-group'>
            <label htmlFor='rafLink' className='font-weight-bold ml-0'>
                Share Your Unique Referral Link:
            </label>
            <div className='row'>
                <div className='col'>
                    <small>Copy the link below to share with friends</small>
                </div>
            </div>
            <div className='row'>
                <div className='col'>
                    <input type='text' id='rafLink' name='rafLink' value={rafLink} className='raf-input' ref={textAreaRef} readOnly={rafLink} />
                </div>
                <button className='raf-btn' onClick={() => copy(textAreaRef)}>
                    Copy
                </button>
            </div>
        </div>
    ); // end return
}; // end RafLink

/**********************************************************************
 * Component:  ShareRafCode
 * Purpose:   Allows the user to copy the RAF code to paste later
 *
 * Props: -   code - the RAF promo Code
 *            copy - the copy function from the parent component
 *
 * APIs used: None
 */
const ShareRafCode = ({ code, copy }) => {
    const textAreaRef = useRef(null);
    return (
        <div className='form-group'>
            <label htmlFor='rafCode' className='font-weight-bold ml-0'>
                Share Your Unique Referral Code:
            </label>
            <div className='row'>
                <div className='col'>
                    <small>Copy the code below to share with friends.</small>
                </div>
            </div>
            <div className='row'>
                <div className='col'>
                    <input type='text' id='rafCode' name='rafCode' defaultValue={code} className='raf-input' ref={textAreaRef} readOnly={code} />
                </div>
                <button className='raf-btn' onClick={() => copy(textAreaRef)}>
                    Copy
                </button>
            </div>
        </div>
    ); // end return
}; // end ShareRafCode

export { RafForm, EmailFriendInvite, RafLink, ShareRafCode };
