import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { getCMSObject, Login } from 'sg-ui-components';
import { mapStateToProps, mapDispatchToProps } from '../Store';
import Layout from '../Components/Layout';

/**********************************************************************
 * Component:  LoginPageTemplate
 * Purpose:    Handles Login and redirects user to various places
 *             based on the player action table.
 *
 * Props:       user -  user data store
 *              actions - store actions (apis)
 *              children -  any child components to be rendered
 *
 * APIs used:   None
 *
 *  Notes:
 */
const LoginPage = ({ loading, user, config, cmsSourceFirebase, actions, match }) => {


    const updateDashboard = async () => {
        await actions.userActions.getDashboard();
    };

    if (user.loggedIn) {
        // Check if there is a redirection parameter and redirect accordingly if there are no
        // actions to do in the Actions table.
        const redirect = new URLSearchParams(document.location.search).get('redirect');
        const partner = new URLSearchParams(document.location.search).get('partner');
        if (user.loggedIn && user.player?.actions?.length === 0 && partner === 'evelopers' && redirect && config.config.lottery_host) {
            window.location.href = config.config.lottery_host + '/' + redirect;
            return null;
        } else if (user.loggedIn && user.player?.actions?.length === 0 && partner === 'store' && redirect && config.config.store_uri) {
            window.location.href = config.config.store_uri + '/' + redirect;
            return null;
        } else {
            updateDashboard();
            return <Redirect to={redirect || '/enter'} />;
        }
    }

    const loginTelescript = getCMSObject('data.components.teleScripts.login.jsonBlock');

    return (
        <Layout {...{ user, config, cmsSourceFirebase, actions, match }}>
            <Login
                user={user}
                loading={loading}
                actions={actions}
                telescript={loginTelescript}
                options = {{
                    variant: 'theme-primary',
                    hasCollapser: false,
                    forgotPasswordLink: '/forgot-password',
                    registerLink: '/register',
                    contactLink: '/feedback#contact',
                }}
            />
        </Layout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
