var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { TeleScript } from '../../../index';
import { Button, Modal } from 'react-bootstrap';
var AllocatableModal = function (_a) {
    var show = _a.show, setShow = _a.setShow, entry = _a.entry, telescript = _a.telescript, _b = _a.AllocatableForm, AllocatableForm = _b === void 0 ? function () { return _jsx(_Fragment, {}); } : _b;
    var handleModalClose = function () {
        setShow(false);
    };
    return (_jsxs(Modal, __assign({ show: show, onHide: handleModalClose, backdrop: 'static', keyboard: false, size: 'lg', dialogClassName: 'allocatable-form-modal', contentClassName: 'allocatable-form-modal' }, { children: [_jsx(Modal.Body, { children: AllocatableForm && AllocatableForm(entry) }), _jsx(Modal.Footer, { children: _jsx(Button, __assign({ variant: 'primary', onClick: handleModalClose }, { children: _jsx(TeleScript, __assign({ line: telescript === null || telescript === void 0 ? void 0 : telescript.buttonClose }, { children: "Close" })) })) })] }), entry.id));
};
export default AllocatableModal;
