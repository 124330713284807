var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { TeleScript, TableDesktop, TableMobile, formatDate } from '../../../index';
var WinnerLink = function (_a) {
    var _b, _c;
    var selected = _a.selected, telescript = _a.telescript, _d = _a.displayHeadersOverride, displayHeadersOverride = _d === void 0 ? null : _d, _e = _a.displayRowsOverride, displayRowsOverride = _e === void 0 ? null : _e;
    var _f = useState(false), pageLoaded = _f[0], setPageLoaded = _f[1];
    var _g = useState(false), apiCalled = _g[0], setApiCalled = _g[1];
    var _h = useState(false), isFetching = _h[0], setIsFetching = _h[1];
    var _j = useState(false), show = _j[0], setShow = _j[1];
    var _k = useState(null), selectedDrawings = _k[0], setSelectedDrawings = _k[1];
    var _l = useState(null), error = _l[0], setError = _l[1];
    useEffect(function () {
        function fetchData() {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    if (!pageLoaded) {
                        setPageLoaded(true);
                    }
                    return [2 /*return*/];
                });
            });
        }
        fetchData();
    }, []);
    var fetchApiData = function () { return __awaiter(void 0, void 0, void 0, function () {
        var isDrawingIdValid, winnersUrl;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    isDrawingIdValid = Number.parseInt(selected === null || selected === void 0 ? void 0 : selected.id) > 0;
                    if (!isDrawingIdValid)
                        return [2 /*return*/, false];
                    winnersUrl = '/api/sweepstake/drawing/' + (selected === null || selected === void 0 ? void 0 : selected.id) + '/winners';
                    setIsFetching(true);
                    return [4 /*yield*/, fetch(winnersUrl)
                            .then(function (res) { return res.json(); })
                            .then(function (result) {
                            //? for debug: console.log('🎁 Winners:', selected, result);
                            setIsFetching(false);
                            setApiCalled(true);
                            //? tier fields can also contain the prize name in special cases like GA
                            result = result.map(function (element) {
                                var _a;
                                return __assign(__assign({}, element), { prize_name: (_a = element.prize_name) !== null && _a !== void 0 ? _a : element.tier });
                            });
                            setSelectedDrawings(result);
                            setShow(true);
                        }, function (error) {
                            //? for debug:
                            console.log('🔴 Winners error:', selected, error);
                            setIsFetching(false);
                            setApiCalled(true);
                            setError(error);
                            setShow(false);
                        })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var formatPrizeAmount = function (amount) {
        var currencyFormatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
        });
        // divide by 100 as our value is in cents
        return currencyFormatter.format(amount / 100);
    };
    var handleClose = function () {
        setShow(false);
    };
    var handleShow = function () {
        setShow(true);
    };
    var handleKeyShow = function (event) {
        if (event.key === 'Enter') {
            setShow(true);
        }
    };
    var handleKeyFetch = function (event) {
        if (event.key === 'Enter') {
            fetchApiData();
        }
    };
    var handleKeyClose = function (event) {
        if (event.key === 'Enter') {
            setShow(false);
        }
    };
    //? before api call
    if (pageLoaded && !apiCalled) {
        if (!isFetching) {
            return (_jsx(_Fragment, { children: _jsx("a", __assign({ id: "winners-link-".concat(selected.id), className: 'winners-link', role: 'button', tabIndex: '0', onKeyDown: handleKeyFetch, onClick: function () { return fetchApiData(); } }, { children: _jsx(TeleScript, __assign({ line: telescript === null || telescript === void 0 ? void 0 : telescript.viewWinners }, { children: "View Winners" })) })) }));
        }
        else {
            return (_jsx("span", __assign({ className: 'loading-text' }, { children: _jsx(TeleScript, __assign({ line: telescript === null || telescript === void 0 ? void 0 : telescript.loadingWinners }, { children: "Loading Winners..." })) })));
        }
    }
    // Headers: Define your headers in an object
    // Rows: Add fields based on headers and the data
    var displayTable = function (data) {
        var _a;
        var entries = data !== null && data !== void 0 ? data : [];
        var formatAddress = function (city, state) {
            if (city === void 0) { city = null; }
            if (state === void 0) { state = null; }
            if (city && state)
                return "".concat(city, ", ").concat(state);
            return "".concat(city !== null && city !== void 0 ? city : '').concat(state !== null && state !== void 0 ? state : '');
        };
        var displayHeaders = displayHeadersOverride
            ? displayHeadersOverride
            : {
                name: 'Name',
                cityState: 'City, State',
                prize: 'Prize',
            };
        var displayRows = displayRowsOverride
            ? displayRowsOverride(entries)
            : entries.map(function (entry) {
                var _a, _b, _c, _d;
                return (__assign(__assign({}, entry), { fields: {
                        name: "".concat((_a = entry.firstname) !== null && _a !== void 0 ? _a : '', " ").concat((_b = entry.lastname) !== null && _b !== void 0 ? _b : ''),
                        cityState: formatAddress(entry.city, entry.state),
                        prize: (_d = (_c = entry.prize_name) !== null && _c !== void 0 ? _c : entry.tier) !== null && _d !== void 0 ? _d : '',
                    } }));
            });
        var displayOptions = {
            tableId: 'winnersTable',
            isLoaded: pageLoaded,
            messageNoRows: (_a = telescript === null || telescript === void 0 ? void 0 : telescript.noWinners) !== null && _a !== void 0 ? _a : 'No winners',
        };
        return (_jsxs(_Fragment, { children: [_jsx(TableDesktop, { headers: displayHeaders, rows: displayRows, options: displayOptions }), _jsx(TableMobile, { headers: displayHeaders, rows: displayRows, options: displayOptions })] }));
    };
    //? after api call
    if (apiCalled && selectedDrawings) {
        var prizeNameDisplay_1 = {};
        selectedDrawings.forEach(function (element) {
            if (element.prize_amount && !prizeNameDisplay_1[element.prize_name]) {
                prizeNameDisplay_1[element.prize_name] = formatPrizeAmount(element.prize_amount);
            }
        });
        return (_jsxs(_Fragment, { children: [_jsx("a", __assign({ id: "winners-link-".concat(selected.id), className: 'winners-link', role: 'button', tabIndex: '0', onKeyDown: handleKeyShow, onClick: handleShow }, { children: _jsx(TeleScript, __assign({ line: telescript === null || telescript === void 0 ? void 0 : telescript.viewWinners }, { children: "View Winners" })) })), _jsxs(Modal, __assign({ id: "winners-modal-".concat(selected.id), dialogClassName: 'winners-modal', backdropClassName: 'winners-modal-backdrop', show: show, onHide: handleClose, centered: true }, { children: [_jsxs(Modal.Header, { children: [_jsxs(TeleScript, __assign({ line: telescript === null || telescript === void 0 ? void 0 : telescript.header, replace: {
                                        '%SWEEPSTAKE_NAME%': (_b = selected === null || selected === void 0 ? void 0 : selected.sweepstake) !== null && _b !== void 0 ? _b : '',
                                        '%SWEEPSTAKE_DRAW_DATE%': (_c = formatDate.monthDayYear(selected === null || selected === void 0 ? void 0 : selected.draw_date)) !== null && _c !== void 0 ? _c : '',
                                    } }, { children: [selected === null || selected === void 0 ? void 0 : selected.sweepstake, " Winners - Drawing: ", formatDate.monthDayYear(selected === null || selected === void 0 ? void 0 : selected.draw_date)] })), _jsx("a", __assign({ className: 'modal-close-element', title: 'Close', tabIndex: '0', role: 'button', onKeyDown: handleKeyClose, onClick: handleClose }, { children: "X" }))] }), _jsx(Modal.Body, { children: _jsx("div", __assign({ className: 'winners' }, { children: displayTable(selectedDrawings) })) }), _jsx(Modal.Footer, { children: _jsx(TeleScript, { line: telescript === null || telescript === void 0 ? void 0 : telescript.footnote }) })] }))] }));
    }
    else if (apiCalled && error) {
        return 'error';
    }
    else {
        return null;
    }
};
export default WinnerLink;
