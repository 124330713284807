var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import BoxedContent from '../BoxedContent/BoxedContent';
import DisplayContent from '../DisplayContent/DisplayContent';
import replaceTemplateStrings from '../../utils/replaceTemplateStrings';
var ActionNotification = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h;
    var action = _a.action, _j = _a.attributes, attributes = _j === void 0 ? {} : _j, _k = _a.userActions, userActions = _k === void 0 ? [] : _k, _l = _a.options, options = _l === void 0 ? [] : _l;
    var playerActions = (_b = options === null || options === void 0 ? void 0 : options.playerActions) !== null && _b !== void 0 ? _b : [];
    var playerActionLogoutMessage = (_c = options === null || options === void 0 ? void 0 : options.playerActionLogoutMessage) !== null && _c !== void 0 ? _c : '';
    var playerActionsLeftMessage = (_d = options === null || options === void 0 ? void 0 : options.playerActionsLeftMessage) !== null && _d !== void 0 ? _d : '';
    var content = playerActions.find(function (item) { return item.actionType === action; });
    return (_jsx(Modal, __assign({ show: content ? true : false, size: 'lg', backdrop: 'static', dialogClassName: 'action-notification', autoFocus: true, keyboard: false, centered: true }, { children: _jsxs(BoxedContent, __assign({ note: 'Action notification box', isActive: true, hasCollapser: false, title: (_e = content === null || content === void 0 ? void 0 : content.actionTitle) !== null && _e !== void 0 ? _e : '' }, { children: [_jsx(DisplayContent, __assign({ isVisible: content === null || content === void 0 ? void 0 : content.actionImageUrl }, { children: _jsx("div", __assign({ className: 'action-notification-image' }, { children: _jsx("img", { src: content === null || content === void 0 ? void 0 : content.actionImageUrl }) })) })), _jsx("div", __assign({ className: 'action-notification-description' }, { children: _jsx("div", { dangerouslySetInnerHTML: { __html: replaceTemplateStrings((_f = content === null || content === void 0 ? void 0 : content.actionDescription) !== null && _f !== void 0 ? _f : '', attributes) } }) })), _jsxs("div", __assign({ className: 'action-notification-buttons' }, { children: [_jsx("a", __assign({ className: 'btn btn-primary mx-2', href: replaceTemplateStrings(content === null || content === void 0 ? void 0 : content.actionCtaUrl, attributes) }, { children: (_g = content === null || content === void 0 ? void 0 : content.actionCtaText) !== null && _g !== void 0 ? _g : '' })), _jsxs(DisplayContent, __assign({ isVisible: userActions.length >= 2 }, { children: [_jsx("hr", {}), _jsx("div", __assign({ className: 'action-notification-alert' }, { children: _jsxs("small", { children: [_jsx("div", { dangerouslySetInnerHTML: {
                                                    __html: replaceTemplateStrings(playerActionsLeftMessage !== null && playerActionsLeftMessage !== void 0 ? playerActionsLeftMessage : '', { '%NUMBER_OF_ACTIONS%': (_h = userActions.length) !== null && _h !== void 0 ? _h : 1 }),
                                                } }), _jsx("ul", __assign({ className: 'action-notification-alert-list' }, { children: userActions.map(function (action, index) {
                                                    var _a, _b;
                                                    return (_jsx("li", { children: (_b = (_a = playerActions.find(function (item) { return item.actionType === action; })) === null || _a === void 0 ? void 0 : _a.actionTitle) !== null && _b !== void 0 ? _b : action }, index));
                                                }) }))] }) }))] })), _jsx(DisplayContent, __assign({ isVisible: content === null || content === void 0 ? void 0 : content.actionHasLogout }, { children: _jsx("div", { dangerouslySetInnerHTML: { __html: playerActionLogoutMessage } }) }))] }))] })) })));
};
export default ActionNotification;
