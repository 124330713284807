var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import BoxedContent from '../BoxedContent/BoxedContent';
import { CMSContent } from '../CMSContent/CMSContent';
import getCMSObject from '../../utils/getCMSObject';
var ScratchNumberExample = function (_a) {
    var cmsSourceFirebase = _a.cmsSourceFirebase;
    return (_jsxs(_Fragment, { children: [_jsx(CMSContent, { localStorageObject: 'webContent', contentPath: 'data.ticketEntry.findScratchNumbers.contentHTML', cmsSourceFirebase: cmsSourceFirebase }), _jsxs("div", __assign({ className: 'row' }, { children: [_jsxs("div", __assign({ className: 'col-lg-6' }, { children: [_jsx(CMSContent, { localStorageObject: 'webContent', contentPath: 'data.ticketEntry.findScratchNumbers.scratchPanelLeft.contentHTML', cmsSourceFirebase: cmsSourceFirebase }), _jsx(CMSContent, { localStorageObject: 'webContent', contentPath: 'data.ticketEntry.findScratchNumbers.scratchPanelLeft.image', className: 'img-fluid mx-auto d-block', cmsSourceFirebase: cmsSourceFirebase })] })), _jsxs("div", __assign({ className: 'col-lg-6' }, { children: [_jsx(CMSContent, { localStorageObject: 'webContent', contentPath: 'data.ticketEntry.findScratchNumbers.scratchPanelRight.contentHTML', cmsSourceFirebase: cmsSourceFirebase }), _jsx(CMSContent, { localStorageObject: 'webContent', contentPath: 'data.ticketEntry.findScratchNumbers.scratchPanelRight.image', className: 'img-fluid mx-auto d-block', cmsSourceFirebase: cmsSourceFirebase })] }))] }))] }));
};
var FindScratchNumbers = function (_a) {
    var cmsSourceFirebase = _a.cmsSourceFirebase;
    var hash = 'scrach_ticket_sample';
    return (_jsx(BoxedContent, __assign({ note: 'Find Scratch Numbers section', hash: hash, isActive: false, isVisible: true, title: getCMSObject('data.ticketEntry.findScratchNumbers.contentHeaderText') }, { children: _jsx(ScratchNumberExample, { cmsSourceFirebase: cmsSourceFirebase }) })));
};
export default {
    FindScratchNumbers: FindScratchNumbers,
    ScratchNumberExample: ScratchNumberExample,
};
