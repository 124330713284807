var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ContentBox, ContentBoxHead, ContentBoxBody, TeleScript } from '../../../index';
import { Link } from 'react-router-dom';
var PasswordExpired = function (_a) {
    var updateNowLink = _a.updateNowLink, telescript = _a.telescript;
    return (_jsxs("div", __assign({ className: 'login-container' }, { children: [_jsx("div", { className: 'my-5' }), _jsxs(ContentBox, __assign({ variant: 'theme-blue' }, { children: [_jsx(ContentBoxHead, { children: _jsx(TeleScript, __assign({ line: telescript === null || telescript === void 0 ? void 0 : telescript.title }, { children: "PASSWORD EXPIRED" })) }), _jsxs(ContentBoxBody, { children: [_jsx("div", __assign({ className: 'text-center' }, { children: _jsx("p", { children: _jsx(TeleScript, __assign({ line: telescript === null || telescript === void 0 ? void 0 : telescript.expiredContent }, { children: "Your password has expired. Please update it now to continue using all of the features of 2nd Chance" })) }) })), _jsx(Link, __assign({ to: updateNowLink, className: 'btn theme-btn btn-block theme-secondary' }, { children: _jsx(TeleScript, __assign({ line: telescript === null || telescript === void 0 ? void 0 : telescript.updateNowLink }, { children: "Update Now" })) }))] })] }))] })));
};
export default PasswordExpired;
