var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import convertStatusToTelescriptValue from '../../utils/convertStatusToTelescriptValue';
import { ContentBox, ContentBoxHead, ContentBoxBody, ContentCollapser, isHash, TableDesktop, TableMobile, TeleScript, formatDate } from '../../../index';
var MyPointsForDrawing = function (_a) {
    var _b, _c, _d, _e, _f, _g;
    var pointsHistory = _a.pointsHistory, actions = _a.actions, telescript = _a.telescript, _h = _a.options, options = _h === void 0 ? {
        variant: 'theme-primary',
        hasCollapser: true,
        hash: 'points-for-drawing',
        pageSize: 5,
        linkOlderDrawings: '',
        linkOlderDrawingsTarget: '_blank',
        promotionDetailsLink: '/points-for-drawing-details',
    } : _h;
    var promotionDetailsLink = (_b = options === null || options === void 0 ? void 0 : options.promotionDetailsLink) !== null && _b !== void 0 ? _b : '/points-for-drawing-details';
    var _j = useState(false), pageLoaded = _j[0], setPageLoaded = _j[1];
    var _k = useState(1), page = _k[0], setPage = _k[1];
    var _l = useState((_c = options === null || options === void 0 ? void 0 : options.pageSize) !== null && _c !== void 0 ? _c : 5), pageSize = _l[0], setPageSize = _l[1];
    var hash = (_d = options === null || options === void 0 ? void 0 : options.hash) !== null && _d !== void 0 ? _d : 'points-for-drawing';
    useEffect(function () {
        function fetchData() {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!!pageLoaded) return [3 /*break*/, 2];
                            return [4 /*yield*/, actions.pointsForDrawingsActions.getPlayerPFDTransactions()];
                        case 1:
                            _a.sent();
                            setPageLoaded(true);
                            _a.label = 2;
                        case 2: return [2 /*return*/];
                    }
                });
            });
        }
        fetchData();
        return function () {
            var _a;
            setPage(1);
            setPageSize((_a = options === null || options === void 0 ? void 0 : options.pageSize) !== null && _a !== void 0 ? _a : 5);
            setPageLoaded(false);
        };
    }, []);
    var loadMoreData = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, actions.pointsForDrawingsActions.getPlayerPFDTransactions({ page: page + 1, page_size: pageSize })];
                case 1:
                    _a.sent();
                    setPage(page + 1);
                    return [2 /*return*/];
            }
        });
    }); };
    var loadMoreLink = function () {
        var _a, _b;
        return ((_a = pointsHistory === null || pointsHistory === void 0 ? void 0 : pointsHistory.data) === null || _a === void 0 ? void 0 : _a.length) > 0 && ((_b = pointsHistory === null || pointsHistory === void 0 ? void 0 : pointsHistory.data) === null || _b === void 0 ? void 0 : _b.length) < (pointsHistory === null || pointsHistory === void 0 ? void 0 : pointsHistory.total) ? (_jsx("div", __assign({ className: 'text-center' }, { children: _jsx(Button, __assign({ variant: 'outline-secondary', className: 'btn theme-btn theme-outline', onClick: function () { return loadMoreData(); } }, { children: _jsx(TeleScript, __assign({ line: telescript.seeMoreLinkText }, { children: "See More" })) })) }))) : null;
    };
    var entriesTable = function (pointsHistory) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        var entries = (_a = pointsHistory.data) !== null && _a !== void 0 ? _a : [];
        var displayHeaders = {
            sweeps_name: (_b = telescript === null || telescript === void 0 ? void 0 : telescript.drawName) !== null && _b !== void 0 ? _b : 'Draw Name',
            id: (_c = telescript === null || telescript === void 0 ? void 0 : telescript.entryId) !== null && _c !== void 0 ? _c : 'EntryId',
            updated_at: (_d = telescript === null || telescript === void 0 ? void 0 : telescript.tableDate) !== null && _d !== void 0 ? _d : 'Date/Time',
            points: (_e = telescript === null || telescript === void 0 ? void 0 : telescript.points) !== null && _e !== void 0 ? _e : 'Pts. Each',
            quantity: (_f = telescript === null || telescript === void 0 ? void 0 : telescript.entries) !== null && _f !== void 0 ? _f : 'Entries',
            total: (_g = telescript === null || telescript === void 0 ? void 0 : telescript.total) !== null && _g !== void 0 ? _g : 'Total Points',
            wallet_status: (_h = telescript === null || telescript === void 0 ? void 0 : telescript.status) !== null && _h !== void 0 ? _h : 'Status',
        };
        var displayRows = entries.map(function (transaction) {
            var _a, _b, _c, _d, _e, _f;
            return (__assign(__assign({}, transaction), { fields: {
                    sweeps_name: _jsx(Link, __assign({ to: "".concat(promotionDetailsLink, "/").concat(transaction.sweeps_id) }, { children: (_a = transaction.sweeps_name) !== null && _a !== void 0 ? _a : '' })),
                    id: transaction.id,
                    updated_at: (_b = formatDate === null || formatDate === void 0 ? void 0 : formatDate.dateTimeWithMeridiem(transaction.updated_at)) !== null && _b !== void 0 ? _b : '',
                    points: (_c = Number(transaction.points) / Number(transaction.quantity)) !== null && _c !== void 0 ? _c : '',
                    quantity: (_d = transaction.quantity) !== null && _d !== void 0 ? _d : '',
                    total: (_e = transaction.points) !== null && _e !== void 0 ? _e : '',
                    wallet_status: (_jsx("div", __assign({ className: 'text-uppercase' }, { children: convertStatusToTelescriptValue((_f = transaction.wallet_status) !== null && _f !== void 0 ? _f : '', 'StatusText', telescript) }))),
                } }));
        });
        var displayOptions = {
            tableId: 'points-for-drawing',
            isLoaded: pageLoaded,
            messageNoRows: (_j = telescript === null || telescript === void 0 ? void 0 : telescript.messageNoRows) !== null && _j !== void 0 ? _j : 'You currently have no new Points For Drawing Points.',
        };
        return (_jsxs(_Fragment, { children: [_jsx(TableDesktop, { headers: displayHeaders, rows: displayRows, options: displayOptions }), _jsx(TableMobile, { headers: displayHeaders, rows: displayRows, options: displayOptions })] }));
    };
    return (_jsxs(ContentBox, __assign({ variant: (_e = options === null || options === void 0 ? void 0 : options.variant) !== null && _e !== void 0 ? _e : 'theme-primary', id: hash, show: isHash(hash) ? 'true' : 'false' }, { children: [_jsxs(ContentBoxHead, { children: [_jsx(TeleScript, __assign({ line: telescript === null || telescript === void 0 ? void 0 : telescript.title }, { children: "My Points For Drawing" })), (options === null || options === void 0 ? void 0 : options.hasCollapser) === true ? _jsx(ContentCollapser, {}) : ''] }), _jsx(ContentBoxBody, { children: _jsx("div", __assign({ className: 'row' }, { children: _jsxs("div", __assign({ className: 'col' }, { children: [entriesTable(pointsHistory), loadMoreLink(), _jsx("div", __assign({ className: 'text-center pfd-more-link' }, { children: _jsx("a", __assign({ href: (_f = options === null || options === void 0 ? void 0 : options.linkOlderDrawings) !== null && _f !== void 0 ? _f : '/', target: (_g = options === null || options === void 0 ? void 0 : options.linkOlderDrawingsTarget) !== null && _g !== void 0 ? _g : '_blank' }, { children: _jsx(TeleScript, __assign({ line: telescript === null || telescript === void 0 ? void 0 : telescript.seeOlderDrawing }, { children: "See Older Points For Drawings Entries" })) })) }))] })) })) })] })));
};
export default MyPointsForDrawing;
