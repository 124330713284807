var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import TeleScript from '../TeleScript/TeleScript';
import './pfd-style.scss';
import DisplayContent from '../DisplayContent/DisplayContent';
var Prizes = function (_a) {
    var prizes = _a.prizes, telescript = _a.telescript;
    if (!prizes || prizes.length === 0) {
        return (_jsx("div", __assign({ className: 'row w-100 d-flex justify-content-center' }, { children: _jsx(TeleScript, __assign({ line: telescript === null || telescript === void 0 ? void 0 : telescript.prizeEmptyState }, { children: "No prizes available." })) })));
    }
    return (_jsx("div", { children: prizes.map(function (prize) {
            var _a, _b, _c, _d, _e, _f;
            return (_jsxs("div", __assign({ className: 'row mb-2' }, { children: [_jsx("div", __assign({ className: 'col-md-4 col-sm-12' }, { children: ((_a = prize.extra) === null || _a === void 0 ? void 0 : _a.image) ? (_jsx("img", { className: 'img-fluid prize-img', src: (_b = prize.extra) === null || _b === void 0 ? void 0 : _b.image, alt: (_d = (_c = prize.extra) === null || _c === void 0 ? void 0 : _c.imageAltText) !== null && _d !== void 0 ? _d : 'Prize image' })) : (_jsx("div", { className: 'prize-filler-image' })) })), _jsxs("div", __assign({ className: 'col-md-8 col-sm-12' }, { children: [_jsx("h2", { children: prize.name }), _jsx(DisplayContent, __assign({ isVisible: !!((_e = prize.extra) === null || _e === void 0 ? void 0 : _e.description) }, { children: _jsx("div", { dangerouslySetInnerHTML: { __html: (_f = prize.extra) === null || _f === void 0 ? void 0 : _f.description } }) }))] }))] }), prize.id));
        }) }));
};
export default Prizes;
