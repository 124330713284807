var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
import { createSlice } from '@reduxjs/toolkit';
import { loadingSlice } from './LoadingStore';
import { userSlice } from './UserStore';
export var defaultActivityState = {
    lastRequested: null,
    requestError: false,
    pageSize: 5,
    page: 0,
    total: 0,
    ticketPage: 0,
    ticketTotal: 0,
    ticketPageSize: 5,
    activities: [],
    ticketActivities: [],
};
// REDUCERS
// basic actions that affect this object only
var getActivityReducer = function (state, action) {
    var _a = action.payload, newActivities = _a.activities, page = _a.page, total = _a.total;
    if (newActivities) {
        // TODO: There's a bug in the API that causes more results than expected to be returned, truncate it for now
        if (newActivities && newActivities.length > state.pageSize) {
            newActivities.length = state.pageSize;
        }
        // Merge newActivities in existing activities making sure that they're slotted in the
        // right place (potentially overwriting existing records if the API messed up)
        var activities = __spreadArray([], state.activities, true);
        activities.length = state.pageSize * (page - 1);
        newActivities && activities.push.apply(activities, newActivities);
        return __assign(__assign({}, state), { lastRequested: Date.now(), activities: activities, page: page, total: total });
    }
    else {
        return state;
    }
};
var getTicketActivityReducer = function (state, action) {
    var _a, _b, _c;
    var data = action.payload;
    var newTicketActivities = (_a = data.activities) !== null && _a !== void 0 ? _a : [];
    var page = (_b = data.page) !== null && _b !== void 0 ? _b : 1;
    var total = (_c = data.total) !== null && _c !== void 0 ? _c : 0;
    if (newTicketActivities || page > 1) {
        // Merge newTicketActivities in existing TicketActivities.
        var ticketActivities = JSON.parse(JSON.stringify(state.ticketActivities));
        newTicketActivities && ticketActivities.push.apply(ticketActivities, newTicketActivities);
        return __assign(__assign({}, state), { lastRequested: Date.now(), ticketActivities: ticketActivities, ticketPage: page, ticketTotal: total });
    }
    else {
        return state;
    }
};
var clearActivityReducer = function (state, action) {
    return defaultActivityState;
};
var resetTicketActivityReducer = function (state, action) {
    return __assign(__assign({}, state), { lastRequested: Date.now(), requestError: false, ticketPage: defaultActivityState.ticketPage, ticketTotal: defaultActivityState.ticketTotal, ticketPageSize: defaultActivityState.ticketPageSize, ticketActivities: defaultActivityState.ticketActivities });
};
var setRequestErrorReducer = function (state, action) {
    return __assign(__assign({}, state), { lastRequested: Date.now(), requestError: true });
};
export var activitySlice = createSlice({
    name: 'activity',
    initialState: defaultActivityState,
    reducers: {
        getActivities: getActivityReducer,
        getTicketActivity: getTicketActivityReducer,
        clearActivity: clearActivityReducer,
        resetTicketActivity: resetTicketActivityReducer,
        setRequestError: setRequestErrorReducer,
    },
    extraReducers: (_a = {},
        _a[userSlice.actions.logout] = function (state) {
            return defaultActivityState;
        },
        _a),
});
// ACTUAL ACTIONS
// a place to make the actions more complex if necessary
var getActivities = function (dispatch, payload, apiClient) { return __awaiter(void 0, void 0, void 0, function () {
    var result;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(loadingSlice.actions.setLoading({ action: 'getEntries' }));
                return [4 /*yield*/, apiClient().getActivity(payload)];
            case 1:
                result = _a.sent();
                dispatch(activitySlice.actions.getActivities(result));
                dispatch(loadingSlice.actions.unsetLoading({ action: 'getEntries' }));
                return [2 /*return*/];
        }
    });
}); };
var getTicketActivity = function (dispatch, payload, apiClient) { return __awaiter(void 0, void 0, void 0, function () {
    var result;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(loadingSlice.actions.setLoading({ action: 'getTicketActivity' }));
                return [4 /*yield*/, apiClient().getTicketActivity(payload)];
            case 1:
                result = _a.sent();
                dispatch(activitySlice.actions.getTicketActivity(result));
                dispatch(loadingSlice.actions.unsetLoading({ action: 'getTicketActivity' }));
                return [2 /*return*/];
        }
    });
}); };
//actions that get exported to the views
export var activityActions = function (dispatch, apiClient) {
    return {
        getActivities: function (payload) { return getActivities(dispatch, payload, apiClient); },
        getTicketActivity: function (payload) { return getTicketActivity(dispatch, payload, apiClient); },
        resetTicketActivity: function (payload) { return dispatch(activitySlice.actions.resetTicketActivity(payload)); },
    };
};
